import React, { useState } from "react";
import { submitUserReview } from "../../api/review";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const LoggedForm = () => {
  const { t } = useTranslation();
  const [reviewData, setReviewData] = useState({});
  const token = useSelector((state) => state.auth.token);
  
  const errorMSG = () => toast.error(t('Something went wrong'), {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
  
  const succesMSG = () => toast.success(t('Sent with success'), {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const handleInputChange = (event) => {
    setReviewData({
      ...reviewData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await submitUserReview(reviewData, token);

      if (response.status === 400) {
        errorMSG();
      } else {
        succesMSG(); // Function call on success
      }
    } catch (error) {
      errorMSG(); // Function call in case of an error during the API call
    }
    console.log(reviewData);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>	
        <div className="row">
          <div className="col-sm-12 mb-30"> 
            <div className="info__details-left-contact-form-item">
              <i className="fal fa-pen"></i>
              <textarea
                type="text"
                name="review"
                placeholder={t("Review")}
                required
                onChange={handleInputChange}
              ></textarea>
            </div>										
          </div>
          <div className="col-lg-12">										
            <div className="info__details-left-contact-form-item">
              <button className="theme-btn-1" type="submit">
                {t("Submit")}<i className="far fa-long-arrow-right"></i>
              </button>
            </div>										
          </div>
        </div>							
      </form>	                  
    </>
  );
};

export default LoggedForm;
