import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_SVHOST + '/api',
});

export const uploadFile = async (file) => {
  let formData = new FormData();
  formData.append('file', file);

  try {
    const response = await api.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};
