import React from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { pageTitle } from '../../PageTitle';
import PolicyBanner from '../../../components/PolicyBanner'; 
import { useTranslation } from 'react-i18next';

const TermsPage = () => {
    const { t } = useTranslation();

    pageTitle(t('Terms of use'));

    return (
        <>
        <Header></Header>
        <div className="info__details section-padding">
            <div className="container">
                <PolicyBanner text={t('Welcome to Our Terms of use page')} />
                <div className="mb-5"></div>
                <div className="row">
                    <div className="offset-xl-2 offset-lg-2 col-xl-8 col-lg-8 lg-mb-30">
                        <div className="info__details-left">
                            <h3 className="mb-20 mt-20">{t('+999 policies and terms')}</h3>
                            <p>{t('Maecenas tincidunt hendrerit odio sed consectetur. Duis porta purus sapien, eget pretium augue consectetur ut. Nunc nibh augue, pretium quis imperdiet pellentesque, molestie eget nisi. Sed rutrum sit amet eros ac egestas. Maecenas tincidunt dolor in massa iaculis, vitae dignissim sem finibus. Pellentesque elementum vel arcu sit amet rhoncus. Nulla at eleifend lorem. Praesent et ex sed metus egestas feugiat. Donec velit libero, feugiat ac dictum vel, dignissim id ante. Praesent hendrerit posuere condimentum.')}</p>
                            <p>{t('Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        </>
    );
};

export default TermsPage;
