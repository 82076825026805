import React, { useState } from 'react';
import { submitUserMessage } from '../../api/messages';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const LoggedForm = () => {
    const { t } = useTranslation();
    const initialFormState = {
        subject: '',
        message: ''
    };
    const [messageData, setMessageData] = useState(initialFormState);
    const token = useSelector((state) => state.auth.token);
    
    const errorMSG = () => toast.error(t('Something went wrong'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
    
    const succesMSG = () => toast.success(t('Sent with success'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    });
        
    const handleInputChange = (event) => {
        setMessageData({
            ...messageData,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await submitUserMessage(messageData, token);
    
            if (response.status === 400) {
                errorMSG();
            } else {
                succesMSG(); // Function call on success
                setMessageData(initialFormState); // Clear the form
            }
        } catch (error) {
            errorMSG(); // Function call in case of an error during the API call
        }
    };

    return (
        <>
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-sm-12 mb-25">
                    <div className="contact__area-bottom-form-item">
                        <input 
                            type="text" 
                            name="subject" 
                            placeholder={t("Subject")} 
                            required 
                            onChange={handleInputChange} 
                            value={messageData.subject} // Bind the value to messageData state
                        /> 
                    </div>
                </div>
                <div className="col-sm-12 mb-30">
                    <div className="contact__area-bottom-form-item">
                        <textarea 
                            type="text" 
                            name="message" 
                            placeholder={t("Message")} 
                            required 
                            onChange={handleInputChange} 
                            value={messageData.message} // Bind the value to messageData state
                        ></textarea>
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="contact__area-bottom-form-item">
                        <button className="theme-btn-1" type="submit">{t("Submit Now")}<i className="far fa-long-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </form>            
        </>
    );
};

export default LoggedForm;
