import React from 'react';
import { useTranslation } from 'react-i18next';

const Work = () => {
    const { t } = useTranslation();

    return (
        <div className="work__area section-padding">
            <div className="container">
                <div className="row mb-60">
                    <div className="col-xl-12">
                        <div className="work__area-title lg-t-center">
                            <span className="subtitle one">{t('How IT Work')}</span>
                            <h2>{t('Simple Work Process')}</h2>
                        </div>
                    </div>
                </div>
                <div className="row mb-110">
                    <div className="col-xl-4 col-lg-4 col-md-6 pr-110 lg-mb-30">
                        <div className="work__area-item">
                            <span>01</span>
                            <div className="work__area-item-icon">
                                <img src="assets/img/icon/work-1.png" alt="" />
                            </div>
                            <div className="work__area-item-content">
                                <h4>{t('Concept Creation')}</h4>
                                <p>{t('pellentesque in dolor vel malesuada. Donec finibus libero odio')}</p>
                            </div>
                            <div className="work__area-item-shape">
                                <img src="assets/img/icon/shape-1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 pl-55 pr-55 md-mb-30">
                        <div className="work__area-item">
                            <span>02</span>
                            <div className="work__area-item-icon">
                                <img src="assets/img/icon/work-2.png" alt="" />
                            </div>
                            <div className="work__area-item-content">
                                <h4>{t('Sketch Drawing')}</h4>
                                <p>{t('pellentesque in dolor vel malesuada. Donec finibus libero odio')}</p>
                            </div>
                            <div className="work__area-item-shape">
                                <img src="assets/img/icon/shape-2.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 pl-110">
                        <div className="work__area-item">
                            <span>03</span>
                            <div className="work__area-item-icon">
                                <img src="assets/img/icon/work-3.png" alt="" />
                            </div>
                            <div className="work__area-item-content">
                                <h4>{t('3d Print')}</h4>
                                <p>{t('pellentesque in dolor vel malesuada. Donec finibus libero odio')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Work;
