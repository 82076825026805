import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { pageTitle } from '../PageTitle';
import UsefulFilter from './UsefulFilter';

const Useful = () => {
    pageTitle('Useful');
    return (
        <>
        <Header></Header>
        <UsefulFilter></UsefulFilter>
        <Footer></Footer>            
        </>
    );
};

export default Useful;