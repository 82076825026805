import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchReviews, deleteReview } from '../../../api/review';

function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [expandedReviewId, setExpandedReviewId] = useState(null);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function loadReviews() {
      const data = await fetchReviews(token);
      if (data) {
        setReviews(data.reverse().map(review => ({ ...review, wasExpanded: false })));
      }
    }
    loadReviews();
  }, [token]);

  const toggleReview = (id) => {
    setReviews(reviews.map(review => 
      review._id === id ? { ...review, isExpanded: !review.isExpanded, wasExpanded: true } : { ...review, isExpanded: false }
    ));
    setExpandedReviewId(expandedReviewId === id ? null : id);
  };

  const renderReviewText = (review) => {
    const maxCharacters = 100;
    return review.isExpanded ? review.review : `${review.review.substring(0, maxCharacters)}...`;
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      await deleteReview(reviewId, token);
      setReviews(reviews.filter(review => review._id !== reviewId));
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <div className="messages-container">
      {reviews.map(review => (
        <div
          key={review._id}
          className={`message-item ${review.wasExpanded ? 'was-expanded' : ''} ${review.isExpanded ? 'expanded' : ''}`}
          onClick={() => toggleReview(review._id)}
        >
          <h2>{review.review}</h2>
          <p>{renderReviewText(review)}</p>
          <button
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteReview(review._id);
            }}
          >
            &#10005;
          </button>
          {review.isExpanded && (
            <div className="message-details">
              <p>Name: {review.name}</p>
              <p>Surname: {review.surname}</p>
              <p>Email: {review.email}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Reviews;