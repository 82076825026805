import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <div className="banner__area-main" style={{backgroundImage: `url('assets/img/banner.jpg')`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="banner__area-main-content"> 
                            <span>{t('BEST_3D_VISUALIZER')}</span>
                            <h1>{t('Check this out')}</h1>
                            <Link className="theme-btn-1" to="/model">{t('Go to 3d visualizier')} <i className="fal fa-long-arrow-right"></i></Link> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="banner__area-main-bottom">
                <div className="container">
                    <div className="banner__area-main-bottom-icon">
                        <ul>
                            <li><Link to=""><i className="fab fa-youtube"></i></Link></li>
                            <li><Link to=""><i className="fab fa-telegram"></i></Link></li>
                            <li><Link to=""><i className="fab fa-instagram"></i></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;