import React from 'react';
import { Link } from 'react-router-dom';
import { pageTitle } from '../PageTitle';
import { useTranslation } from 'react-i18next';

const Error = () => {
    const { t } = useTranslation();
    pageTitle(t('Error'));
    return (
        <div className="error__page">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="error__page-content">
                            <h1>404</h1>
                            <p>{t('PageNotFound')}</p>
                            <Link className="theme-btn-1" to="/">{t('GoBackHome')}<i className="fal fa-long-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Error;
