import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  file: null,
  fileName: '',
  materials: [],
  selectedMaterial: null,
  colors: [],
  selectedColor: null,
  quantity: 1,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setStlFileRedux: (state, action) => {
      state.file = action.payload;
    },
    setFileName: (state, action) => {
      state.fileName = action.payload;
    },
    setMaterials: (state, action) => {
      state.materials = action.payload;
    },
    setSelectedMaterial: (state, action) => {
      state.selectedMaterial = action.payload;
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setSelectedColor: (state, action) => {
      state.selectedColor = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
  },
});

export const { setStlFileRedux, setFileName, setMaterials, setSelectedMaterial, setColors, setSelectedColor, setQuantity } = orderSlice.actions;

export const selectSelectedMaterial = (state) => state.order.selectedMaterial;
export const selectSelectedColor = (state) => state.order.selectedColor;

export default orderSlice.reducer;