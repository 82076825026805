import React from 'react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { pageTitle } from '../PageTitle';
import PortfolioFilter from './PortfolioFilter';

const Portfolio = () => {
    pageTitle('Portfolio');
    return (
        <>
        <Header></Header>
        <PortfolioFilter></PortfolioFilter>
        <Footer></Footer>            
        </>
    );
};

export default Portfolio;