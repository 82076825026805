import React from 'react'



function Graph() {

  return (
    <>
      <a rel="noopener noreferrer" target='_blank' href='https://analytics.google.com/analytics/web/provision/?authuser=0#/p408066473/reports/intelligenthome'>GA4</a>
    </>
  )
}

export default Graph