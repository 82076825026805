import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  deleteMaterial,
  addMaterial,
  getAllMaterials,
} from "../../../../api/orderOptions";

function MaterialsComponent() {
  const [materials, setMaterials] = useState([]);
  const [newMaterial, setNewMaterial] = useState("");
  const token = useSelector((state) => state.auth.token);


  const loadMaterials = async () => {
    const data = await getAllMaterials(token);
    if (data) {
      setMaterials(data);
    }
  };

  const handleAddMaterial = async (e) => {
    e.preventDefault();
    const data = await addMaterial({ name: newMaterial }, token);
    if (data) {
      setMaterials([...materials, data]);
      setNewMaterial("");
    }
  };

  const handleDeleteMaterial = async (materialId) => {
    const isSuccess = await deleteMaterial(materialId, token);
    if (isSuccess) {
      setMaterials(materials.filter((material) => material.id !== materialId));
      await loadMaterials();
    }
  };

  useEffect(() => {
    loadMaterials();
  });

  return (
    <div className="options">
      <form className="optionsForm" onSubmit={handleAddMaterial}>
        <input 
          className="col-3"
          value={newMaterial}
          onChange={(e) => setNewMaterial(e.target.value)}
          placeholder="Material"
          type="text"
        />
        <button type="submit">Add</button>
      </form>
      <ul>
        {materials.map((material) => (
          <li className="col-3" key={material._id}>
            {material.name}{" "}
            <button
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteMaterial(material._id);
            }}
          >
            &#10005;
          </button>
          </li>
        ))}
      </ul>
      
    </div>
  );
}

export default MaterialsComponent;
