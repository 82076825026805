import React, { } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { pageTitle } from "../PageTitle";

const MailConfrmation = () => {

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  pageTitle("Mail confirmation");
  return (
    <>
      <Header></Header>
      <div className="contact__area section-padding">
        <div className="container">
          <div className="card-container">
            <div className="card" style={{height:"250px"}}>
              <div className="card-front">
                <div className="formContainer">
                  <div className="formWrapper">
                    <span className="logo">Mail confirmation</span>
                    <form
                      autoComplete="off"
                      className="row justify-content-center"
                      onSubmit={handleFormSubmit}
                    >
                    <input 
                        type="text"
                        placeholder="Confirm code"
                     />
                    </form>

                    <button type="button" className="loginWithGoogleButton">
                      Confirm code
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default MailConfrmation;
