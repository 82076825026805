import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Video from "../Home/Video/Video";
import { pageTitle } from "../PageTitle";
import Sidebar from "./Sidebar";
import searchData from '../../api/text/search.json';
import { useTranslation } from 'react-i18next';

const Infopage1 = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(searchData);
  }, []);

  pageTitle("Info-page");
  return (
    <>
      <Header></Header>
      <div className="info__details section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 lg-mb-30">
              <div className="info__details-left">
                <img src="assets/img/info-7.jpg" alt="" />
                <div className="info__details-left-meta"></div>
                <h3 className="mb-20">
                  {data.length > 0 ? t(data[0].textKey) : t("Loading...")}
                </h3>
                <p>
                  {data.length > 1 ? t(data[1].textKey) : t("Loading...")}
                </p>
                <Video videoId="XxVg_s8xAms" />
                <div className="info__details-left-box">
                  <p>
                    {data.length > 2 ? t(data[2].textKey) : t("Loading...")}
                  </p>
                  <h6>{data.length > 3 ? t(data[3].textKey) : t("Loading...")}</h6>
                </div>
                <p>
                  {data.length > 4 ? t(data[4].textKey) : t("Loading...")}
                </p>
                <div className="row mt-40 mb-40">
                  <div className="col-sm-6 sm-mb-30">
                    <div className="info__details-left-list">
                      <img
                        className="img__full"
                        src="assets/img/details-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="info__details-left-list">
                      <img
                        className="img__full"
                        src="assets/img/details-2.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <p className="mb-20">
                  {data.length > 5 ? t(data[5].textKey) : t("Loading...")}
                </p>
                <p>
                  {data.length > 6 ? t(data[6].textKey) : t("Loading...")}
                </p>

                                                {/* <div className="info__details-left-related">
                  <div className="row align-items-center">
                    <div className="col-md-7 md-mb-30">
                      <div className="info__details-left-related-tag">
                        <h6>Tags :</h6>
                        <ul>
                          <li>
                            <Link to="">Popular</Link>
                          </li>
                          <li>
                            <Link to="">Design</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <Sidebar></Sidebar>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Infopage1;
