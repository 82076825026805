import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DropDown from "./DropDown";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../../api/auth";
import { logout, clearUser } from "../../redux/slices/authSlice";
import { Home, Page, Portfolio, User, CurrentLanguage, OtherLanguages } from "./Menu";
import { useTranslation } from "react-i18next";

const Header = ({ variant }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleLogoutClick = () => {
    handleLogout();
    dispatch(logout());
    dispatch(clearUser());
    navigate("/");
  };

  const name = useSelector((state) => state.auth.name);
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  return (
    <>
      <div
        className={`header__area ${variant ? variant : ""} header__sticky ${
          isSticky ? "header__sticky-active" : ""
        }`}
      >
        <div className="container">
          <div className="header__area-box">
            <div className="header__area-box-logo">
              <Link to="/">
                <img src="assets/img/logo.png" width={140} alt="ur logo" />
              </Link>
            </div>
            <div className="header__area-box-menu">
              <div className="header__area-box-main-menu">
                
              {isAuthenticated ? (
                // auth
                <ul
                  className="mobile__menu"
                  style={{ display: `${mobileToggle ? "block" : "none"}` }}
                >
                <li className="header_name_menu">
                    <Link to="/">{t('Welcome')}, {name}</Link>
                  </li>
                  <Home />
                  <Page />
                  <li className="menu-item-has-children">
                    <Link>{t('Portfolio')}</Link>
                    <DropDown>
                      <ul className="sub-menu">
                        <Portfolio />
                      </ul>
                    </DropDown>
                  </li>
                  <li>
                    <Link to="/contact">{t('Contact')}</Link>
                  </li>
                  <li>
                    <Link to="/useful">{t('Useful')}</Link>
                  </li>
                  <li className="header_name_menu">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        handleLogoutClick();
                      }}
                    >
                      {t('Logout')}
                    </a>
                  </li>
                  <li className="menu-item-has-children lang-mobile">
                    <CurrentLanguage />
                    <DropDown>
                      <ul className="sub-menu">
                        <OtherLanguages />
                      </ul>
                    </DropDown>
                  </li>
                </ul>
              ) : (
                // unauth
                <ul
                  className="mobile__menu"
                  style={{ display: `${mobileToggle ? "block" : "none"}` }}
                >
                  <Home />
                  <Page />
                  <li className="menu-item-has-children">
                    <Link>{t('Portfolio')}</Link>
                    <DropDown>
                      <ul className="sub-menu">
                        <Portfolio />
                      </ul>
                    </DropDown>
                  </li>
                  <li>
                    <Link to="/contact">{t('Contact')}</Link>
                  </li>
                  <li>
                    <Link to="/useful">{t('Useful')}</Link>
                  </li>
                  <li className="header_name_menu">
                    <Link to="/login">{t('Log in')}</Link>
                  </li>
                  <li className="menu-item-has-children lang-mobile">
                    <CurrentLanguage />
                    <DropDown>
                      <ul className="sub-menu">
                        <OtherLanguages />
                      </ul>
                    </DropDown>
                  </li>
                </ul>
              )}

                <span
                  className={
                    mobileToggle
                      ? "mobile-menu mobile-menu-active"
                      : "mobile-menu"
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </div>

              {isAuthenticated ? (
                // auth
                <div className="menu__user-action">
                  <div className="header__area-box-contact header_name_submenu">
                    <div className="header__area-box-contact-icon">
                      {/* <i className="fal fa-user"></i> */}
                    </div>
                    <div className="header__area-box-main-menu">
                      <ul>
                        <li className="menu-item-has-children header_name">
                          <Link>{`${t('Welcome')}, ${name}`}</Link>
                          <DropDown>
                            <ul className="sub-menu">
                              <User />
                              <li>
                                <a
                                  href="/"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleLogoutClick();
                                  }}
                                >
                                  {t('Logout')}
                                </a>
                              </li>
                            </ul>
                          </DropDown>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="header__area-box-contact header_name_submenu lang-pc">
                    <div className="header__area-box-main-menu">
                      <ul>
                        <li className="menu-item-has-children header_name">
                          <CurrentLanguage />
                          <DropDown>
                            <ul className="sub-menu lang_box_width">
                              <OtherLanguages />
                            </ul>
                          </DropDown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                // unauth
                <div className="menu__user-action">
                  <div className="header__area-box-contact header_name_submenu">
                    <div className="header__area-box-contact-icon">
                      {/* <i className="fal fa-user"></i> */}
                    </div>
                    <div className="header__area-box-main-menu">
                      <ul>
                        <li>
                          <Link to="/login">{t('Log in')}</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="header__area-box-contact header_name_submenu lang-pc">
                    <div className="header__area-box-main-menu">
                      <ul>
                        <li className="menu-item-has-children header_name">
                          <CurrentLanguage />
                          <DropDown>
                            <ul className="sub-menu lang_box_width">
                              <OtherLanguages />
                            </ul>
                          </DropDown>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
