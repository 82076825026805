import React, { useRef, useEffect } from 'react';
import { useLoader} from '@react-three/fiber';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import * as THREE from 'three';

const STLModel = ({ stlFile, onDimensionsCalculated }) => {
  const geometry = useLoader(STLLoader, stlFile);
  const ref = useRef();

  useEffect(() => {
    const calculateDimensionsAndVolume = () => {
      if (ref.current) {
        const box = new THREE.Box3().setFromObject(ref.current);
        const dimensions = box.getSize(new THREE.Vector3());

        const getVolume = (geometry) => {
          let position = geometry.attributes.position;
          let faces = position.count / 3;
          let sum = 0;
          let p1 = new THREE.Vector3(),
            p2 = new THREE.Vector3(),
            p3 = new THREE.Vector3();
          for (let i = 0; i < faces; i++) {
            p1.fromBufferAttribute(position, i * 3 + 0);
            p2.fromBufferAttribute(position, i * 3 + 1);
            p3.fromBufferAttribute(position, i * 3 + 2);
            sum += signedVolumeOfTriangle(p1, p2, p3);
          }
          return sum;
        };

        const signedVolumeOfTriangle = (p1, p2, p3) => {
          return p1.dot(p2.cross(p3)) / 6.0;
        };

        const volume = getVolume(geometry);
        onDimensionsCalculated(dimensions, volume);
      }
    };

    calculateDimensionsAndVolume();
    geometry.center();
  }, [geometry, onDimensionsCalculated]);


  return (
    <group ref={ref}>
      <mesh geometry={geometry}>
        <meshMatcapMaterial />
      </mesh>
    </group>
  );
};

export default STLModel;