import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AboutPage from './Pages/AboutPage/AboutPage';
import Contact from './Pages/Contact/Contact';
import Error from './Pages/Error/Error';
import Home from './Pages/Home/Home/Home';
import Login from './Pages/LoginPage/LoginPage';
import SignIn from './Pages/SigninPage/SignInPage';
import Portfolio from './Pages/Portfolio/Portfolio';
import Terms from './Pages/Policies/TermsPage/TermsPage';
import Policy from './Pages/Policies/PrivacyPolicyPage/PrivacyPolicyPage';
import PortfolioDetails from './Pages/Portfolio/PortfolioDetails';
import Order from './Pages/OrderPage/OrderPage';
import Review from './Pages/Review/Review';
import Preloader from './Pages/Preloader/Preloader';
import ScrollTop from "./Pages/ScrollTop/ScrollTop";
import Agreement from "./Pages/GoogleAgreement/GoogleAgreement";
import Infopage1 from './Pages/InfoPages/InfoPage1';
import Confirmation from "./Pages/MailConfirmation/MailConfirmation";
import AdminDashboard from './Pages/Admin/AdminDashboard';
import Useful from './Pages/Useful/Useful';
import { ProtectedRoute } from './utils/admin';
import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function App() {
  const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <>
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
        {loader && <Preloader />}
        <Router>
          <ScrollTop />
          <PageViewsWrapper />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/model" element={<PortfolioDetails />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/terms-of-use" element={<Terms />} />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route path="/order" element={<Order />} />
            <Route path="/review" element={<Review />} />
            <Route path="/agreement" element={<Agreement />} />
            <Route path='/info' element={<Infopage1 />} />
            <Route path="/confirmation" element={<Confirmation />} />
            <Route path="/useful" element={<Useful />} />
            <Route path="*" element={<Error />} />
            <Route path="/adminDashboard" element={<ProtectedRoute component={AdminDashboard} />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

function PageViewsWrapper() {
  usePageViews();
  return null;
}

export default App;
