import React from 'react'

function Chat() {
  return (

    <>
         Chat
    </>

  )
}

export default Chat