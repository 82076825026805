import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import levenshtein from 'fast-levenshtein';
import data from '../../api/text/search.json';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [queryWords, setQueryWords] = useState([]);

    const handleSearchInput = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = useCallback((event) => {
        if (event) event.preventDefault();
        if (!searchQuery || searchQuery.length < 1) {
            setSearchResults([]);
            return;
        }
    
        const words = searchQuery.toLowerCase().split(' ');
        setQueryWords(words);
        const threshold = 1;
        const minWordLength = 1;
    
        const results = data.filter(item => {
            return words.some(queryWord =>
                queryWord.length >= minWordLength &&
                item.textKey && t(item.textKey).toLowerCase().split(' ').some(textWord =>
                    levenshtein.get(queryWord, textWord) <= threshold
                )
            );
        });
    
        setSearchResults(results);
    }, [searchQuery, t]);

    const highlightText = (text, queryWords) => {
        return (
            <span>
                {text.split(' ').map((word, index) => {
                    const match = queryWords.find(qw => levenshtein.get(qw.toLowerCase(), word.toLowerCase()) <= 2);
                    if (match && match.length <= word.length) {
                        const matchStart = word.toLowerCase().indexOf(match);
                        const beforeMatch = word.substring(0, matchStart);
                        const matchText = word.substring(matchStart, matchStart + match.length);
                        const afterMatch = word.substring(matchStart + match.length);
                        return (
                            <React.Fragment key={index}>
                                {beforeMatch}
                                <strong style={{ color: '#F94D1D' }}>{matchText}</strong>
                                {afterMatch}
                                {' '}
                            </React.Fragment>
                        );
                    } else {
                        return <React.Fragment key={index}>{word} </React.Fragment>;
                    }
                })}
            </span>
        );
    };

    useEffect(() => {
        if (searchQuery) {
            handleSearchSubmit();
        }
    }, [searchQuery, handleSearchSubmit]);

    return (
        <>
            <div className="all__sidebar ml-25 xl-ml-0">
                <div className="all__sidebar-item">
                    <h4>{t('Search Here')}</h4>
                    <div className="all__sidebar-item-search">
                        <form onSubmit={handleSearchSubmit}>
                            <input type="text" placeholder={t("Search")} onChange={handleSearchInput} />
                            <button type="submit"><i className="fal fa-search"></i></button>
                        </form>
                    </div>
                </div>

            {searchResults.length > 0 ? (
                    <div className="all__sidebar-item">
                        <h4>{t("Search Results")}</h4>
                        {searchResults.map(item => (
                            <div key={item.id} className="all__sidebar-item-post-item">
                                <div className="all__sidebar-item-post-item-content">
                                    <h5><Link to={item.pageresult}>{highlightText(t(item.textKey), queryWords)}</Link></h5>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : searchQuery && (
                    <div className="all__sidebar-item">
                        <h4>{t("Search Results")}</h4>
                        <p>{t("No results found.")}</p>
                    </div>
                )}

                                            {/* <div className="all__sidebar-item"> future some posts update
                <h4>Also</h4>
                <div className="all__sidebar-item-post">
                    <div className="all__sidebar-item-post-item">
                        <div className="all__sidebar-item-post-item-image">
                            <Link to="/news-details"><img src="assets/img/post-1.jpg" alt="" /></Link>
                        </div>
                        <div className="all__sidebar-item-post-item-content">
                            <h5><Link to="/news-details">Sample Brochure Design</Link></h5>
                        </div>
                    </div>
                    <div className="all__sidebar-item-post-item">
                        <div className="all__sidebar-item-post-item-image">
                            <Link to="/news-details"><img src="assets/img/post-2.jpg" alt="" /></Link>
                        </div>
                        <div className="all__sidebar-item-post-item-content">
                            <h5><Link to="/news-details">Sample Brochure Design</Link></h5>
                        </div>
                    </div>
                </div>
            </div> */}


            
           {/* <div className="all__sidebar-item"> future tag update
                <h4>Tags</h4>
                <div className="all__sidebar-item-tag">
                    <ul>
                        <li><Link to="">Design</Link></li>
                    </ul>
                </div>
            </div> */}
        </div>            
        </>
    );
};

export default Sidebar;
