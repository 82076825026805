import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 

import authReducer from './slices/authSlice';
import layerHeightReducer from './slices/layerHeightSlice';
import orderReducer from './slices/orderSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}
const rootReducer = combineReducers({
  order: orderReducer,
  layerHeight: layerHeightReducer,
  auth: authReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
});
let persistor = persistStore(store)

export { store, persistor }