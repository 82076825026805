import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import STLModel from "../Portfolio/STLModel";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useSelector } from "react-redux";
import { pageTitle } from "../PageTitle";
import { createOrder } from "../../api/order";
import { uploadFile } from "../../api/file";
import 'react-tooltip/dist/react-tooltip.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OrderPage = () => {
  const { t } = useTranslation();
  pageTitle(t("Make an order"));

  const location = useLocation();
  const navigate = useNavigate();
  const stlFileFromState = location.state?.stlFile;
  const [modelDimensions, setModelDimensions] = useState(null);
  const [modelVolume, setModelVolume] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    city: "",
    zCode: "",
    street: "",
    companyName: "",
    comment: "",
    material: "",
    color: "",
    quantity: 1,
    fileName: "",
    fileUrl: ""
  });

  const selectedMaterial = useSelector((state) => state.order.selectedMaterial);
  const selectedColor = useSelector((state) => state.order.selectedColor);
  const quantity = useSelector((state) => state.order.quantity);
  const fileName = useSelector((state) => state.order.fileName);
  const selectedHeightIndex = useSelector((state) => state.layerHeight.selectedHeightIndex);
  const selectedHeight = useSelector((state) => state.layerHeight.layerHeight[selectedHeightIndex]);
  const layerHeight = selectedHeight.height;

  const requiredFields = ["name", "surname", "phone", "email", "city", "zCode", "street"];
  const emptyFields = requiredFields.filter(field => !formData[field]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      material: selectedMaterial,
      color: selectedColor,
      quantity: quantity,
      fileName: fileName,
    }));
  }, [selectedMaterial, selectedColor, quantity, fileName]);

  const handleDimensionsCalculated = (dimensions, volume) => {
    const roundedDimensions = {
      x: dimensions.x.toFixed(2),
      y: dimensions.y.toFixed(2),
      z: dimensions.z.toFixed(2),
    };
    setModelDimensions(roundedDimensions);
    setModelVolume(volume.toFixed(3));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitOrder = async (event) => {
    event.preventDefault();

    // Checking mandatory fields before uploading a file
    if (emptyFields.length > 0) {
      toast.error(`${t('Please fill in the following fields')}: ${emptyFields.join(", ")}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    try {
      // File upload only if validation is passed
      const fileResponse = await uploadFile(stlFileFromState);
      // console.log("File uploaded, response:", fileResponse);

      if (!fileResponse || !fileResponse.url) {
        throw new Error("File upload failed");
      }

      const updatedFormData = {
        ...formData,
        fileUrl: fileResponse.url,
      };
      // console.log("Updated formData:", updatedFormData);

      const response = await createOrder(updatedFormData);
      // console.log("Order response:", response);

      if (response) {
        setFormData({
          name: "",
          surname: "",
          email: "",
          phone: "",
          city: "",
          zCode: "",
          street: "",
          companyName: "",
          comment: "",
          material: "",
          color: "",
          quantity: 1,
          fileName: "",
          fileUrl: "",
        });
        toast.success(t("Order submitted successfully!"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // Redirects to the home page after successful submission
        setTimeout(() => {
          navigate("/");
        }, 1);  // Delay before redirecting (m.sec.)
      } else {
        toast.error(t("An error occurred while submitting the order."), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      toast.error(t("An error occurred while submitting the order."), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      // console.error("Order submission error:", error);
    }
  };

  return (
    <>
      <Header />
      <div className="portfolio__details section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 lg-mb-30">
              <div className="portfolio__details-main-left">
                <div className="all__sidebar">
                  <div className="all__sidebar-item-details-list">
                    <h6>{t('Material')}:</h6>
                    {selectedMaterial ? <span>{selectedMaterial}</span> : <span>{t('No material selected')}</span>}
                  </div>
                  <div className="all__sidebar-item-details-list">
                    <h6>{t('Color')}:</h6>
                    {selectedColor ? <span>{selectedColor}</span> : <span>{t('No color selected')}</span>}
                  </div>
                  <div className="all__sidebar-item-details-list">
                    <h6>{t('Quantity')}:</h6>
                    {quantity ? <span>{quantity}</span> : <span>{t('No quantity selected')}</span>}
                  </div>
                  <div className="all__sidebar-item details main textNone">
                    <h4 className="textNone">{t('Model Details')}</h4>
                    <div className="all__sidebar-item-details ">
                      {modelDimensions && (
                        <div>
                          <div className="all__sidebar-item-details-list">
                            <h6>x :</h6>
                            <span>{modelDimensions.x} mm</span>
                          </div>
                          <div className="all__sidebar-item-details-list">
                            <h6>y :</h6>
                            <span>{modelDimensions.y} mm</span>
                          </div>
                          <div className="all__sidebar-item-details-list">
                            <h6>z :</h6>
                            <span>{modelDimensions.z} mm</span>
                          </div>
                          {modelVolume !== null && (
                            <div className="all__sidebar-item-details-list">
                              <h6>{t('Volume')} :</h6>
                              <span>{modelVolume} mm³</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6">
              <div className="info__details-left-contact-form">
                <form onSubmit={handleSubmitOrder}>
                  <div className="row">
                    <div className="col-sm-6 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-user"></i>
                        <input
                          className="col-sm-10 sm-mb-30"
                          type="text"
                          name="name"
                          placeholder={t("Full Name")}
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-envelope"></i>
                        <input
                          className="col-sm-10 sm-mb-30"
                          type="text"
                          name="surname"
                          placeholder={t("Surname")}
                          value={formData.surname}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-phone"></i>
                        <input
                          className="col-sm-11 sm-mb-30"
                          type="text"
                          name="phone"
                          placeholder={t("Phone number")}
                          value={formData.phone}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-envelope"></i>
                        <input
                          className="col-sm-11 sm-mb-30"
                          type="email"
                          name="email"
                          placeholder={t("Email Address")}
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-globe"></i>
                        <input
                          className="col-sm-11 sm-mb-30"
                          type="text"
                          name="city"
                          placeholder={t("City")}
                          value={formData.city}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-home"></i>
                        <input
                          className="col-sm-11 sm-mb-30"
                          type="text"
                          name="street"
                          placeholder={t("Street")}
                          value={formData.street}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-mailbox"></i>
                        <input
                          className="col-sm-11 sm-mb-30"
                          type="text"
                          name="zCode"
                          placeholder={t("Zip Code")}
                          value={formData.zCode}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-building"></i>
                        <input
                          className="col-sm-11 sm-mb-30"
                          type="text"
                          name="companyName"
                          placeholder={t("Company name (optional)")}
                          value={formData.companyName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mb-30">
                      <div className="info__details-left-contact-form-item">
                        <i className="fal fa-pen"></i>
                        <textarea
                          name="comment"
                          placeholder={t("Type your comments....")}
                          value={formData.comment}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="contact__area-bottom-form-item">
                      <button className="theme-btn-1" type="submit">
                        {t("Submit Now")}<i className="far fa-long-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3">
              <div className="services__details-left">
                <div>
                  {stlFileFromState && (
                    <Canvas style={{ height: "500px" }}>
                      <PerspectiveCamera makeDefault position={[0, 0, 100]} />
                      <STLModel
                        stlFile={URL.createObjectURL(stlFileFromState)}
                        onDimensionsCalculated={handleDimensionsCalculated}
                        layerHeight={layerHeight}
                      />
                      <OrbitControls />
                    </Canvas>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderPage;
