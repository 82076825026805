import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  token: null,
  name: null,
  surname: null,
  email: null,
  admin: null,
  agreement: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.name = action.payload.name;
      state.surname = action.payload.surname;
      state.email = action.payload.email;
      state.agreement = action.payload.agreement;
    },
    login: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
    },

    setAdmin: (state, action) => {
    state.admin = action.payload;
  },
    clearUser: (state) => {
      state.name = null;
      state.surname = null;
      state.email = null;
      state.admin = null;
      state.agreement = null;
    },
  },
});

export const { login, logout, setUser, clearUser, setAdmin } = authSlice.actions;

export default authSlice.reducer;