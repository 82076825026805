import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, getMe } from "../../api/auth";
import { login, setUser, setAdmin } from "../../redux/slices/authSlice";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import GoogleButton from "../../components/GoogleButton";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { pageTitle } from "../PageTitle";
import axios from "axios";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();

  const succesMSG = () =>
    toast.success(t("Logged with success"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const receivedToken = await loginUser({ email, password }, navigate);
      if (receivedToken) {
        const decodedToken = jwtDecode(receivedToken);
        dispatch(setAdmin(decodedToken.admin));
        dispatch(login(receivedToken));
        succesMSG();
      }
    } catch (error) {
      toast.error(t("Something went wrong"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    if (token) {
      const fetchUserData = async () => {
        const user = await getMe(token);
        dispatch(setUser(user));
      };

      fetchUserData();
    }
  }, [token, dispatch]);

  pageTitle(t("Login"));
  return (
    <>
      <Header></Header>
      <div className="contact__area section-padding">
        <div className="container">
          <div className="card-container">
            <div className="card logIn">
              <div className="card-front">
                <div className="formContainer">
                  <div className="formWrapper">
                    <span className="logo">{t("Login")}</span>
                    <span className="title">{t("Login with email and password")}</span>
                    <form className="row justify-content-center">
                      <Tooltip
                        id="my-tooltip"
                        opacity={1}
                        style={{
                          backgroundColor: "rgb(30, 30, 30)",
                          color: "white",
                          zIndex: "100",
                        }}
                      />
                      <div className="col-md-6 pading-left">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("Email")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6 pading-right">
                        <input
                          className="form-control"
                          type="password"
                          placeholder={t("Password")}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <button
                        className="form-control signIn"
                        onClick={handleLogin}
                      >
                        {t("Sign in")}
                      </button>
                    </form>
                    <span className="or">
                      <hr />
                      <span>{t("OR")}</span>
                      <hr />
                    </span>

                    <div
                      className="sign-in-container"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={t("By signing in with Google, you agree to our terms and conditions.")}
                      style={{ position: "relative" }}
                    >
                      <div className="google-login-tooltip">
                        {t("By signing in with Google, you agree to our terms and conditions.")}
                      </div>
                      <GoogleButton></GoogleButton>
                      <GoogleLogin
                        containerProps={{
                          style: {
                            position: "absolute",
                            opacity: 0,
                            top: "0px",
                            width: "110px",
                          },
                        }}
                        onSuccess={async (credentialResponse) => {
                          const response = await axios.post(
                            `${process.env.REACT_APP_SVHOST}/googlelogin`,
                            {
                              token: credentialResponse.credential,
                            }
                          );
                          succesMSG();
                          const data = response.data;
                          dispatch(
                            setUser({
                              name: data.name,
                              surname: data.surname,
                              email: data.email,
                              agreement: data.agreement,
                            })
                          );
                          dispatch(login(data.token));
                          if (data.agreement) {
                            navigate("/");
                          } else {
                            navigate("/agreement");
                          }
                        }}
                      />
                    </div>
                    <p>
                      {t("Don't have an account?")}{" "}
                      <Link to="/signin" className="link">
                        {t("Register")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default LoginPage;