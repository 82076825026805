import React, { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { pageTitle } from "../PageTitle";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();
  pageTitle("About Us");
  const [skillBar, setSkillBar] = useState(false);

  const counterData = [
    {
      id: 520,
      descriptionKey: "projectCompleteLast5Years",
      value: "",
    },
    {
      id: 120,
      descriptionKey: "clientsHappyWithUs",
      value: "%",
    },
    {
      id: 142,
      descriptionKey: "projectCompleteLast2Years",
      value: "+",
    },
    {
      id: 100,
      descriptionKey: "clientsActiveWithUs",
      value: "%",
    },
  ];

  return (
    <>
      <Header></Header>
      <ScrollTrigger
        onEnter={() => setSkillBar(true)}
        onExit={() => setSkillBar(false)}
      >
        <div className="about__page section-padding pb-100">
          <div className="container">
            <div className="row mb-60">
              <div className="col-xl-6 col-lg-8">
                <div className="about__page-title">
                  <span className="subtitle">{t('aboutUs')}</span>
                  <h2>{t('somethingAboutYourDomain')}</h2>
                </div>
              </div>
            </div>
            <div className="row mb-100">
              <div className="col-xl-12">
                <div className="about__page-area">
                  <div className="about__page-area-experience">
                    <h3>
                      <span className="counter">
                        {skillBar && (
                          <CountUp
                            start={0}
                            end={2}
                            duration={3}
                            delay={0}
                          ></CountUp>
                        )}
                        +
                      </span>
                    </h3>
                    <p>{t('yearsExperienceInOurCompany')}</p>
                  </div>
                  <img src="assets/img/about.jpg" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              {counterData.map((item, id) => (
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6" key={id}>
                  <div className="about__page-area-item">
                    <h2>
                      <span className="counter">
                        {skillBar && (
                          <CountUp
                            start={0}
                            end={item.id}
                            duration={4}
                            delay={0}
                          ></CountUp>
                        )}
                      </span>
                      {item.value}
                    </h2>
                    <p>{t(item.descriptionKey)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ScrollTrigger>
      <div className="client__area">
        <div className="container">
          <div className="row">
            <div className="col-10 col-sm-12">
            {t('aboutTextDescription')}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default AboutPage;
