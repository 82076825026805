import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { pageTitle } from "../PageTitle";
import { setUser } from "../../redux/slices/authSlice";
import axios from "axios";
import { useTranslation } from 'react-i18next';

const GoogleAgreement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();

  useEffect(() => {
    const userAgreement = true;
    if (userAgreement) {
      navigate('/');
    }
  }, [navigate]);
  
  const handleConfirmTerms = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_SVHOST}/api/auth/updateUser`, {
        agreement: true
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        dispatch(setUser({
          ...user,
          agreement: true
        }));
      }
    } catch (error) {
      console.error(error);
    }
    navigate("/");
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  };

  pageTitle("Register");
  return (
    <>
      <Header></Header>
      <div className="contact__area section-padding">
        <div className="container">
          <div className="card-container">
            <div className="card" style={{height:"250px"}}>
              <div className="card-front">
                <div className="formContainer">
                  <div className="formWrapper">
                    <span className="logo">{t('AgreementConfirmation')}</span>
                    <form
                      autoComplete="off"
                      className="row justify-content-center"
                      onSubmit={handleFormSubmit}
                    >
                      <div className="col-sm-12" style={{marginBottom:"15px"}}>
                        <div className="contact__area-bottom-form-item">
                          <label>
                            <Link to="/terms-of-use">
                              &nbsp;{t('ReadTermsAndConditions')}
                            </Link>
                          </label>
                        </div>
                      </div>
                    </form>

                    <button type="button" className="loginWithGoogleButton" onClick={handleConfirmTerms}>
                      {t('ConfirmTerms')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default GoogleAgreement;
