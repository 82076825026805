import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchOrders, deleteOrder } from '../../../api/order';

function Orders() {
  const [orders, setOrders] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function loadOrders() {
      const data = await fetchOrders(token);
      if (data) {
        setOrders(data.reverse().map(order => ({ ...order, wasExpanded: false })));
      }
    }
    loadOrders();
  }, [token]);

  const toggleOrder = (id) => {
    setOrders(orders.map(order => 
      order._id === id ? { ...order, isExpanded: !order.isExpanded, wasExpanded: true } : { ...order, isExpanded: false }
    ));
    setExpandedOrderId(expandedOrderId === id ? null : id);
  };

  const renderOrderText = (order) => {
    const maxCharacters = 100;
    return order.isExpanded ? order.comment : `${order.comment.substring(0, maxCharacters)}...`;
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      await deleteOrder(orderId, token);
      setOrders(orders.filter(order => order._id !== orderId));
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <div className="messages-container">
      {orders.map(order => (
        <div
          key={order._id}
          className={`message-item ${order.wasExpanded ? 'was-expanded' : ''} ${order.isExpanded ? 'expanded' : ''}`}
          onClick={() => toggleOrder(order._id)}
        >
          <h2>{order.fileName}</h2>
          <p>{renderOrderText(order)}</p>
          <button
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteOrder(order._id);
             }}
          >
            &#10005;
          </button>
          {order.isExpanded && (
            <div className="order-details">
              <p>Name: {order.name}</p>
              <p>Surname: {order.surname}</p>
              <p>Email: {order.email}</p>
              <p>Phone: {order.phone}</p>
              <p>City: {order.city}</p>
              <p>zCode: {order.zCode}</p>
              <p>Street: {order.street}</p>
              <p>Company Name: {order.companyName}</p>
              <p>Comment: {order.comment}</p>
              <p>Material: {order.material}</p>
              <p>Color: {order.color}</p>
              <p>Quantity: {order.quantity}</p>
              <p>File url: <a href={order.fileUrl}>download</a></p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Orders;