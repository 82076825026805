import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SVHOST + '/api',
});



export const getAllMaterials = async () => {
    try {
      const response = await api.get('/materials/all');
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('something went wrong:', error);
      return null;
    }
  };
  
  export const deleteMaterial = async (materialId, token) => {
    try {
      const response = await api.delete(`/materials/${materialId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.status === 200 || response.status === 204) {
        return true;
      }
    } catch (error) {
      console.error('Something went wrong:', error);
      throw error;
    }
  }
  
  export const addMaterial = async (material, token) => {
    try {
      const response = await api.post('/materials', material, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };
  
  export const getAllColors = async () => {
    try {
      const response = await api.get('/colors/all');
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('something went wrong:', error);
      return null;
    }
  };
  
  export const deleteColor = async (colorId, token) => {
    try {
      const response = await api.delete(`/colors/${colorId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.status === 200 || response.status === 204) {
        return true;
      }
    } catch (error) {
      console.error('Something went wrong:', error);
      throw error;
    }
  }
  
  export const addColor = async (color, token) => {
    try {
      const response = await api.post('/colors', color, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };
  