import React, { useState, useEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { getAllMaterials, getAllColors } from "../../api/orderOptions";
import STLModel from "./STLModel";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useSelector, useDispatch } from "react-redux";
import { pageTitle } from "../PageTitle";
import {
  setFileName,
  selectSelectedMaterial,
  selectSelectedColor,
  setSelectedMaterial,
  setSelectedColor,
  setQuantity
} from "../../redux/slices/orderSlice";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const PortfolioDetails = () => {
  const { t } = useTranslation();
  pageTitle(t("3d model"));
  const navigate = useNavigate();
  const selectedMaterial = useSelector(selectSelectedMaterial);
  const selectedColor = useSelector(selectSelectedColor);

  const [selectedFileName, setSelectedFileName] = useState("");
  const [stlFile, setStlFile] = useState(null);
  const [modelDimensions, setModelDimensions] = useState(null);
  const [modelVolume, setModelVolume] = useState(null);

  const [materials, setMaterials] = useState([]);
  const [colors, setColors] = useState([]);

  const dispatch = useDispatch();

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setStlFile(file);
      setSelectedFileName(file.name);
      dispatch(setFileName(file.name));
    }
  };

  useEffect(() => {
    if (materials.length === 0) {
      loadMaterials();
    }
    if (colors.length === 0) {
      loadColors();
    }

    return () => {
      if (stlFile) {
        URL.revokeObjectURL(stlFile);
      }
    };
  }, [materials.length, colors.length, stlFile]);

  const handleDimensionsCalculated = (dimensions, volume) => {
    const roundedDimensions = {
      x: dimensions.x.toFixed(2),
      y: dimensions.y.toFixed(2),
      z: dimensions.z.toFixed(2),
    };
    setModelDimensions(roundedDimensions);
    setModelVolume(volume.toFixed(3));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setStlFile(file);
      setSelectedFileName(file.name);
      dispatch(setFileName(file.name));
    }
  };

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove("dragover");
  };

  const handleContainerClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleLabelClick = (e) => {
    e.stopPropagation();
  };

  const loadColors = async () => {
    const data = await getAllColors();
    if (data) {
      setColors(data);
    }
  };

  const loadMaterials = async () => {
    const data = await getAllMaterials();
    if (data) {
      setMaterials(data);
    }
  };

  const handleMaterialChange = (e) => {
    const index = parseInt(e.target.value, 10);
    const material = materials[index].name;
    dispatch(setSelectedMaterial(material));
  };

  const handleColorChange = (e) => {
    const index = parseInt(e.target.value, 10);
    const color = colors[index].name;
    dispatch(setSelectedColor(color));
  };

  const isMaterialSelected = selectedMaterial && materials.findIndex((m) => m.name === selectedMaterial) > 0;
  const isColorSelected = selectedColor && colors.findIndex((c) => c.name === selectedColor) > 0;

  const handleMakeOrder = (event) => {
    event.preventDefault();

    if (!isMaterialSelected && !isColorSelected) {
      toast.error(t("Please select both a material and a color"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!isMaterialSelected) {
      toast.error(t("Please select a material"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!isColorSelected) {
      toast.error(t("Please select a color"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    navigate("/order", { state: { stlFile } });
  };

  return (
    <>
      <Header />
      <div className="portfolio__details section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8">
              <div className="services__details-left">
                <div>
                  <div
                    className="input-container"
                    onClick={handleContainerClick}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleFileInputChange}
                      style={{ display: "none" }}
                    />
                    <label
                      htmlFor="fileInput"
                      className="file-input-label"
                      onClick={handleLabelClick}
                    >
                      {selectedFileName
                        ? selectedFileName
                        : t("Choose File or drag on")}
                    </label>
                  </div>

                  {stlFile && (
                    <Canvas style={{ height: "500px" }}>
                      <PerspectiveCamera makeDefault position={[0, 0, 100]} />
                      <STLModel
                        stlFile={URL.createObjectURL(stlFile)}
                        onDimensionsCalculated={handleDimensionsCalculated}
                      />
                      <OrbitControls />
                    </Canvas>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 lg-mb-30">
              <div className="portfolio__details-main-left">
                <div className="all__sidebar">
                  <div className="select col-12">
                    <select
                      value={materials.findIndex(
                        (m) => m.name === selectedMaterial
                      )}
                      onChange={handleMaterialChange}
                    >
                      {materials.map((material, index) => (
                        <option key={index} value={index}>
                          {material.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="select col-12">
                    <select
                      value={colors.findIndex((c) => c.name === selectedColor)}
                      onChange={handleColorChange}
                    >
                      {colors.map((color, index) => (
                        <option key={index} value={index}>
                          {color.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="input-color col-12">
                    <input
                      className="col-12"
                      type="number"
                      name="quantity"
                      value={useSelector(state => state.order.quantity)}
                      onChange={e => dispatch(setQuantity(Number(e.target.value)))}
                      placeholder={t("Quantity")}
                      min={1}
                    />
                  </div>

                  <div className="all__sidebar-item details main textNone">
                    <h4 className="textNone">{t("Model Details")}</h4>
                    <div className="all__sidebar-item-details ">
                      {modelDimensions && (
                        <div>
                          <div className="all__sidebar-item-details-list">
                            <h6>x :</h6>
                            <span>{modelDimensions.x} mm</span>
                          </div>
                          <div className="all__sidebar-item-details-list">
                            <h6>y :</h6>
                            <span>{modelDimensions.y} mm</span>
                          </div>
                          <div className="all__sidebar-item-details-list">
                            <h6>z :</h6>
                            <span>{modelDimensions.z} mm</span>
                          </div>
                          {modelVolume !== null && (
                            <div className="all__sidebar-item-details-list">
                              <h6>{t("Volume")} :</h6>
                              <span>{modelVolume} mm³</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <button
                      className="theme-btn-1 col-12"
                      onClick={handleMakeOrder}
                    >
                      {t("Make an order")}<i className="fal fa-long-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default PortfolioDetails;
