import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const ProjectStyleData = [
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-7.jpg',
        category: 'machine',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-8.jpg',
        category: 'staff',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-9.jpg',
        category: 'machine',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-10.jpg',
        category: 'PC',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-11.jpg',
        category: 'staff',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-12.jpg',
        category: 'machine',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-13.jpg',
        category: 'PC',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-14.jpg',
        category: 'PC',
    },
    {
        id: uuidv4(),
        image: '../../assets/img/portfolio/portfolio-15.jpg',
        category: 'PC',
    },
];

const ProjectStyleItem = ({ image }) => {
    const { t } = useTranslation();
    return (
        <div className="col-lg-4 col-md-6 portfolio-item mt-30">
            <div className="portfolio__area-main-item">
                <img className="img__full" src={image} alt="" />
                <div className="portfolio__area-main-item-content">
                    <span>{t('Photography')}</span>
                    <h4><Link to="/portfolio-details">{t('Photography')}</Link></h4>
                </div>
            </div>
        </div>
    );
};

const CategoryBtn = ({ name, handleSetCategory, label, className }) => {
    return (
        <button className={className} type="button" onClick={() => handleSetCategory(name)}>
            {label}
        </button>
    );
};

const PortfolioFilter = () => {
    const { t } = useTranslation();
    const [category, setCategory] = useState('all');
    const [projectStyleItem, setProjectStyleItem] = useState([]);

    useEffect(() => {
        category === 'all'
            ? setProjectStyleItem(ProjectStyleData)
            : setProjectStyleItem(ProjectStyleData.filter((data) => data.category === category));
    }, [category]);

    return (
        <div className="portfolio__area-main section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 mb-30">
                        <div className="portfolio__area-main-title">
                            <span className="subtitle-one">{t('Portfolio')}</span>
                            <h2>{t('Create Portfolio')}</h2>
                        </div>
                        <div className="portfolio__area-main-btn">
                            <CategoryBtn className={category === 'all' ? 'active' : null} name="all" label={t('Show All')} handleSetCategory={setCategory}/>
                            <CategoryBtn className={category === 'machine' ? 'active' : null} name="machine" label={t('Machine')} handleSetCategory={setCategory} />
                            <CategoryBtn className={category === 'PC' ? 'active' : null} name="PC" label={t('PC')} handleSetCategory={setCategory} />
                            <CategoryBtn className={category === 'staff' ? 'active' : null} name="staff" label={t('Staff')} handleSetCategory={setCategory} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {projectStyleItem.map((item) => (
                        <ProjectStyleItem key={item.id} image={item.image} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PortfolioFilter;
