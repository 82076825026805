import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  deleteColor,
  addColor,
  getAllColors,
} from "../../../../api/orderOptions";

function ColorsComponent() {
  const [colors, setColors] = useState([]);
  const [newColor, setNewColor] = useState("");
  const token = useSelector((state) => state.auth.token);


  const loadColors = async () => {
    const data = await getAllColors(token);
    if (data) {
      setColors(data);
    }
  };

  const handleAddColor = async (e) => {
    e.preventDefault();
    const data = await addColor({ name: newColor }, token);
    if (data) {
      setColors([...colors, data]);
      setNewColor("");
    }
  };

  const handleDeleteColor = async (colorId) => {
    const isSuccess = await deleteColor(colorId, token);
    if (isSuccess) {
      setColors(colors.filter((color) => color.id !== colorId));
      await loadColors();
    }
  };

  useEffect(() => {
    loadColors();
  });

  return (
    <div className="options">
      <form className="optionsForm" onSubmit={handleAddColor}>
        <input 
          className="col-3"
          value={newColor}
          onChange={(e) => setNewColor(e.target.value)}
          placeholder="Color"
          type="text"
        />
        <button type="submit">Add</button>
      </form>
      <ul>
        {colors.map((color) => (
          <li className="col-3" key={color._id}>
            {color.name}{" "}
            <button
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteColor(color._id);
            }}
          >
            &#10005;
          </button>
          </li>
        ))}
      </ul>
      
    </div>
  );
}

export default ColorsComponent;
