import React from 'react';
import Footer from '../../Footer/Footer';
import Header from '../../Header/Header';
import { pageTitle } from '../../PageTitle';
import Banner from '../Banner/Banner';
import Reviews from '../Reviews/Reviews';
import Work from '../Work/Work';
import Features from '../Features/Features';
import Carousel from '../Infocarousel/Carousel';
const Home = () => {
    pageTitle('3D');
    return (
        <>
            <Header></Header>
            <Banner></Banner>
            <Features></Features>
            <Work></Work>
            <Carousel></Carousel>
            <Reviews></Reviews>
            <Footer></Footer>
        </>
    );
};

export default Home;