import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_SVHOST + '/api',
});

export const fetchOrders = async (token) => {
  try {
    const response = await api.get('/orders/all', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data; 
    }
  } catch (error) {
    console.error('something went wrong:', error);
  }
  return null;
};


export const createOrder = async (orderData) => {
  try {
    console.log('Sending order data:', orderData);
    const response = await api.post('/orders', orderData);
    console.log('Server response:', response);
    if (response.status === 200 || response.status === 201) {
      return response.data.order;
    }
  } catch (error) {
    console.error('Error creating order:', error.response?.data || error.message);
    throw error;
  }
  return null;
};

export const deleteOrder = async (orderId, token) => {
  try {
    const response = await api.delete(`/orders/${orderId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.status === 200 || response.status === 204) {
      return true;
    }
  } catch (error) {
    console.error('something went wrong:', error);
    return null;
  }
};