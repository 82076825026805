import React, { useState } from 'react';
import AddColor from './options/AddColor';
import AddMaterial from './options/AddMaterial';

const AddOptions = () => {
  const [activeTab, setActiveTab] = useState('AddMaterial');

    return (
        <>
          <div className="info__details">
              <div className="admin">
                  <div className="row">
                      <div className="col-2 section-tab">
                          <div className={`tabSelector ${activeTab === 'AddMaterial' ? 'active' : ''}`} onClick={() => setActiveTab('AddMaterial')}>Material</div>
                          <div className={`tabSelector ${activeTab === 'AddColor' ? 'active' : ''}`} onClick={() => setActiveTab('AddColor')}>Color</div>
                      </div>
                      <div className="col-10">
                          {activeTab === 'AddMaterial' && <AddMaterial />}
                          {activeTab === 'AddColor' && <AddColor />}
                      </div>
                  </div>
              </div>
          </div>
        </>
    );
};
export default AddOptions