import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import Accordion from 'react-bootstrap/Accordion';
import { fetchReviews } from '../../../api/review';
import { useTranslation } from 'react-i18next';

const Reviews = () => {
    const { t } = useTranslation();

    const [reviews, setReviews] = useState([]);
    const token = useSelector(state => state.auth.token);

    useEffect(() => {
        const loadReviews = async () => {
            const fetchedReviews = await fetchReviews(token);
            if (fetchedReviews) {
                setReviews(fetchedReviews);
            }
        };
        loadReviews();
    }, [token]);

    return (
        <>
        <div className="reviews__area" style={{backgroundImage: `url('assets/img/reviews.png')`}}>
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-8">
                        <div className="reviews__area-left">
                            <div className="reviews__area-left-title">
                                <h2>{t('Client Reviews')}</h2> 
                            </div>                            
                            <Swiper
                                slidesPerView={1}
                                loop={true}
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                modules={[Pagination]}
                            >
                                {reviews.map((review, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="reviews__area-left-item">
                                            <p>{review.review}</p>
                                            <div className="reviews__area-left-item-client">
                                                {/* <div className="reviews__area-left-item-client-avatar">
                                                    <img src="assets/img/avatar/client-3.jpg" alt="" />
                                                </div> */}
                                                <div className="reviews__area-left-item-client-content">
                                                    <h5>{review.name} {review.surname}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="reviews__area-left-quote"><img src="assets/img/icon/quote.png" alt="" /></div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-4">
                        <div className="faq">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0" className="faq-item">
                                    <Accordion.Header className="faq-item-card-header"><h5>{t('Pellentesque in dolor vel malesuada. Donec')}</h5></Accordion.Header>
                                    <Accordion.Body className='faq-item-card-header-content'><p>{t('pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.')}</p></Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" className="faq-item">
                                    <Accordion.Header className="faq-item-card-header"><h5>{t('Pellentesque in dolor vel malesuada. Donec')}</h5></Accordion.Header>
                                    <Accordion.Body className='faq-item-card-header-content'><p>{t('pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.')}</p></Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" className="faq-item">
                                    <Accordion.Header className="faq-item-card-header"><h5>{t('Pellentesque in dolor vel malesuada. Donec')}</h5></Accordion.Header>
                                    <Accordion.Body className='faq-item-card-header-content'><p>{t('pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.')}</p></Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Reviews;