import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import GoogleButton from "../../components/GoogleButton";
import { pageTitle } from "../PageTitle";
import { registerUser } from "../../api/auth";
import { login, setUser } from "../../redux/slices/authSlice";
import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const SignInPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    surname: "",
    password: "",
    confirmPassword: "",
    email: "",
    agreement: false,
  });

  const handleInputChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleCheckboxChange = () => {
    setUserData({
      ...userData,
      agreement: !userData.agreement,
    });
  };

  const handleRegister = () => {
    registerUser(userData, navigate, succesMSG);
  };
  
  const succesMSG = () =>
    toast.success(t("Sent with succes"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Validation logic
    const requiredFields = ["name", "surname", "password", "confirmPassword", "email"];
    const emptyFields = requiredFields.filter(field => !userData[field]);

    if (emptyFields.length > 0) {
      toast.error(`${t("Please fill in the following fields")}: ${emptyFields.join(", ")}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (userData.password !== userData.confirmPassword) {
      toast.error(t("Passwords do not match"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    if (!userData.agreement) {
      toast.error(t("You must agree to the terms and conditions to register."), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    handleRegister();
  };

  pageTitle(t("Register"));

  return (
    <>
      <Header></Header>
      <div className="contact__area section-padding">
        <div className="container">
          <div className="card-container">
            <div className="card">
              <div className="card-front">
                <div className="formContainer">
                  <div className="formWrapper">
                    <span className="logo">{t("Sign in")}</span>
                    <form
                      className="row justify-content-center"
                      onSubmit={handleFormSubmit}
                    >
                      <Tooltip id="my-tooltip" opacity={1}
                      style={{ backgroundColor: "rgb(30, 30, 30)", color: "white", zIndex:"100"}}/>

                      <div className="col-md-6">
                        <div className="log-input-container">
                          <input
                            className="form-control signInput"
                            type="text"
                            placeholder={t("Name")}
                            name="name"
                            value={userData.name}
                            onChange={handleInputChange}
                          />
                          <span data-tooltip-id="my-tooltip" data-tooltip-content={t("Enter your first name")} className="tooltip-icon">?</span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="log-input-container">
                          <input
                            className="form-control signInput"
                            type="text"
                            placeholder={t("Surname")}
                            name="surname"
                            value={userData.surname}
                            onChange={handleInputChange}
                          />
                          <span data-tooltip-id="my-tooltip" data-tooltip-content={t("Enter your last name")} className="tooltip-icon">?</span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="log-input-container">
                          <input
                            className="form-control signInput"
                            type="password"
                            placeholder={t("Password")}
                            name="password"
                            value={userData.password}
                            onChange={handleInputChange}
                          />
                          <span data-tooltip-id="my-tooltip" data-tooltip-content={t("Password should be at least 8 characters long, with uppercase letters, numbers, and symbols")} className="tooltip-icon">?</span>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="log-input-container">
                          <input
                            className="form-control signInput"
                            type="password"
                            placeholder={t("Confirm password")}
                            name="confirmPassword"
                            value={userData.confirmPassword}
                            onChange={handleInputChange}
                          />
                          <span data-tooltip-id="my-tooltip" data-tooltip-content={t("Re-enter your password")} className="tooltip-icon">?</span>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="log-input-container ">
                          <input
                            className="form-control signInput"
                            type="email"
                            placeholder={t("Email")}
                            name="email"
                            value={userData.email}
                            onChange={handleInputChange}
                          />
                          <span data-tooltip-id="my-tooltip" data-tooltip-content={t("Enter your email address")} className="tooltip-icon">?</span>
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="contact__area-bottom-form-item">
                          <label>
                            <input
                              className="mr-10"
                              type="checkbox"
                              checked={userData.agreement}
                              onChange={handleCheckboxChange}
                            />
                            {t("I agree with")}
                            <Link to="/terms-of-use">
                              &nbsp;{t("terms and condition")}
                            </Link>
                          </label>
                        </div>
                      </div>
                      <div className="signIn">
                        <button
                          className="form-control signIn"
                          type="submit"
                        >
                          {t("Sign in")}
                        </button>
                      </div>
                    </form>
                    <span className="or">
                      <hr />
                      <span>OR</span>
                      <hr />
                    </span>

                    <div
                     className="sign-in-container"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={t("By signing in with Google, you agree to our terms and conditions.")}
                      style={{ position: "relative" }}
                    >
                      <div className="google-login-tooltip">
                        {t("By signing in with Google, you agree to our terms and conditions.")}
                      </div>
                      <GoogleButton></GoogleButton>
                      <GoogleLogin
                        containerProps={{
                          style: {
                            position: "absolute",
                            opacity: 0,
                            top: "0px",
                            width: "110px",
                          },
                        }}
                        onSuccess={async (credentialResponse) => {
                          const response = await axios.post(
                            `${process.env.REACT_APP_SVHOST}/googlelogin`,
                            {
                              token: credentialResponse.credential,
                            }
                          );
                          succesMSG();
                          const data = response.data;
                          // save data in redux
                          dispatch(
                            setUser({
                              name: data.name,
                              surname: data.surname,
                              email: data.email,
                              agreement: data.agreement,
                            })
                          );
                          dispatch(login(data.token));
                          if (data.agreement) {
                            navigate("/");
                          } else {
                            navigate("/agreement");
                          }
                        }}
                      />
                    </div>
                    <p>
                      {t("Have an account?")}{" "}
                      <Link to="/login" className="link">
                        {t("login")}
                      </Link>
                    </p>
                    <Tooltip />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default SignInPage;
