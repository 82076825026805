import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SVHOST + '/api',
});


export const registerUser = async (userData, navigate, callback) => {
  try {
    const response = await api.post('/auth/register', userData);

    if (response.status === 200 || response.status === 201) {
      callback();
      navigate('/login');
    }
  } catch (error) {
    console.error(error);
  }
};



export const loginUser = async (userData, navigate) => {
  try {
    const response = await api.post('/auth/login', userData);
    if (response.status === 200 || response.status === 201) {
      navigate('/');
      return response.data.token;
    } else {
      throw new Error("Incorrect username or password");
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const handleLogout = async (navigate) => {
  try {
    const response = await api.get('/auth/logout');
    if (response.status === 200 || response.status === 201) {
      navigate('/'); // Use navigate to redirect to the main page
    }
  } catch (error) {
    console.error(error);
  }
};

export const getMe = async (token) => {
  try {
    const response = await api.get('/auth/me', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
  return null;
};


