import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "Welcome": "Welcome",
          "BEST_3D_VISUALIZER": "Best 3D visualizer",
          "Check this out": "posmotri na eto",
          "Go to 3d visualizier": "Go to 3d visualizier",
          "Show All": "Show all",
          "Some useful posts": "Some useful posts",
          "Useful": "Useful",
          "Machine": "Machine",
          "PC": "PC",
          "Staff": "Staff",
          "title": "Title",
          "Search Here": "Search Here",
          "Search": "Search",
          "Search Results": "Search Results",
          "No results found.": "No results found.",
          "TEXT_1": "Lorem ipsum dolor sit amet, consectetur adipisicing.",
          "TEXT_2": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis",
          "TEXT_3": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur reprehenderit voluptatem debitis. Voluptates et libero reiciendis labore doloremque! Molestias neque quae quos dignissimos eum rem distinctio cumque repellat fugiat nobis.",
          "TEXT_4": "say about",
          "TEXT_5": "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.",
          "TEXT_6": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat minus nisi rem incidunt illo repellendus dignissimos, totam ex sit enim, officia ipsum, sequi voluptates quasi?",
          "TEXT_7": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga maiores iure consequatur aliquam voluptate vero quae explicabo enim accusantium ad, voluptatibus, ipsum possimus natus excepturi?",
          "Loading...": "Loading...",
          "aboutUs": "About Us",
          "somethingAboutYourDomain": "Something about your domain",
          "yearsExperienceInOurCompany": "Years Experience In Our Company",
          "projectCompleteLast5Years": "Project Complete At Last 5 Years",
          "clientsHappyWithUs": "Clients Happy With Us",
          "projectCompleteLast2Years": "Project Complete At Last 2 Years",
          "clientsActiveWithUs": "Clients Active With Us",
          "aboutTextDescription": " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          "contactUs": "Contact Us",
          "needHelp": "Need help?",
          "phone": "Phone",
          "email": "Email",
          "Your Name": "Your Name",
          "Your Surname": "Your Surname",
          "Email Address": "Email Address",
          "Subject": "Subject",
          "Message": "Message",
          "Submit Now": "Submit Now",
          "Something went wrong": "Something went wrong",
          "Sent with success": "Sent with success",
          "Error": "Error",
          "PageNotFound": "The page you are looking for does not exist!",
          "GoBackHome": "Go Back to Home page",
          "Home": "Home",
          "Portfolio": "Portfolio",
          "Contact": "Contact",
          "TermsOfUse": "Terms of Use",
          "PrivacyPolicy": "Privacy Policy",
          "AgreementConfirmation": "Agreement confirmation",
          "ReadTermsAndConditions": "Read terms and conditions",
          "ConfirmTerms": "Confirm terms",
          "Logout": "Logout",
          "Log in": "Log in",
          "About": "About",
          "3D model": "3D model",
          "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit",
          "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien egetas tincidunt hendrerit": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien egetas tincidunt hendrerit",
          "Pellentesque in dolor vel malesuada. Donec": "Pellentesque in dolor vel malesuada. Donec",
          "pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.": "pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.",
          "How IT Work": "How IT Works",
          "Simple Work Process": "Simple Work Process",
          "Concept Creation": "Concept Creation",
          "Sketch Drawing": "Sketch Drawing",
          "3d Print": "3D Print",
          "pellentesque in dolor vel malesuada. Donec finibus libero odio": "Pellentesque in dolor vel malesuada. Donec finibus libero odio",
          "Logged with success": "Logged with success",
          "Login": "Login",
          "Login with email and password": "Login with email and password",
          "Email": "Email",
          "Password": "Password",
          "Sign in": "Sign in",
          "OR": "OR",
          "By signing in with Google, you agree to our terms and conditions.": "By signing in with Google, you agree to our terms and conditions.",
          "Don't have an account?": "Don't have an account?",
          "Register": "Register",
          "Login with Google": "Login with Google",
          "Make an order": "Make an order",
          "Please fill in the following fields": "Please fill in the following fields",
          "Order submitted successfully!": "Order submitted successfully!",
          "An error occurred while submitting the order.": "An error occurred while submitting the order.",
          "Material": "Material",
          "No material selected": "No material selected",
          "Color": "Color",
          "No color selected": "No color selected",
          "Quantity": "Quantity",
          "No quantity selected": "No quantity selected",
          "Model Details": "Model Details",
          "Volume": "Volume",
          "Full Name": "Full Name",
          "Surname": "Surname",
          "Phone number": "Phone number",
          "City": "City",
          "Street": "Street",
          "Zip Code": "Zip Code",
          "Company name (optional)": "Company name (optional)",
          "Type your comments....": "Type your comments....",
          "Privacy policy": "Privacy policy",
          "Welcome to Our Policies": "Welcome to Our Policies",
          "+999 policies and terms": "+999 policies and terms",
          "Maecenas tincidunt hendrerit odio sed consectetur. Duis porta purus sapien, eget pretium augue consectetur ut. Nunc nibh augue, pretium quis imperdiet pellentesque, molestie eget nisi. Sed rutrum sit amet eros ac egestas. Maecenas tincidunt dolor in massa iaculis, vitae dignissim sem finibus. Pellentesque elementum vel arcu sit amet rhoncus. Nulla at eleifend lorem. Praesent et ex sed metus egestas feugiat. Donec velit libero, feugiat ac dictum vel, dignissim id ante. Praesent hendrerit posuere condimentum.": "Maecenas tincidunt hendrerit odio sed consectetur. Duis porta purus sapien, eget pretium augue consectetur ut. Nunc nibh augue, pretium quis imperdiet pellentesque, molestie eget nisi. Sed rutrum sit amet eros ac egestas. Maecenas tincidunt dolor in massa iaculis, vitae dignissim sem finibus. Pellentesque elementum vel arcu sit amet rhoncus. Nulla at eleifend lorem. Praesent et ex sed metus egestas feugiat. Donec velit libero, feugiat ac dictum vel, dignissim id ante. Praesent hendrerit posuere condimentum.",
          "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.": "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.",
          "Terms of use": "Terms of use",
          "Welcome to Our Terms of use page": "Welcome to Our Terms of use page",
          "3d model": "3d model",
          "Choose File or drag on": "Choose File or drag on",
          "Create Portfolio": "Create Portfolio",
          "Photography": "Photography",
          "Review": "Review",
          "Submit": "Submit",
          "Client Reviews": "Client Reviews",
          "Leave review": "Leave review",
          "Name": "Name",
          "Confirm password": "Confirm password",
          "I agree with terms and condition": "I agree with terms and condition",
          "I agree with": "I agree with",
          "terms and condition": "terms and condition",
          "Have an account?": "Have an account?",
          "login": "login",
          "Enter your first name": "Enter your first name",
          "Enter your last name": "Enter your last name",
          "Password should be at least 8 characters long, with uppercase letters, numbers, and symbols": "Password should be at least 8 characters long, with uppercase letters, numbers, and symbols",
          "Re-enter your password": "Re-enter your password",
          "Enter your email address": "Enter your email address",
          "Please fill in the following fields:": "Please fill in the following fields:",
          "Passwords do not match": "Passwords do not match",
          "You must agree to the terms and conditions to register.": "You must agree to the terms and conditions to register.",
          "Please select both a material and a color": "Please select both a material and a color",
          "Please select a material": "Please select a material",
          "Please select a color": "Please select a color", 
        }
      },
      ru: {
        translation: {
          "Welcome": "Добро пожаловать",
          "BEST_3D_VISUALIZER": "Лучший 3D визуализатор",
          "Check this out": "Проверьте сами",
          "Go to 3d visualizier": "3д визуализатор",
          "Show All": "Показать все",
          "Some useful posts": "Несколько полезных постов",
          "Useful": "Полезное",
          "Machine": "Машина",
          "PC": "ПК",
          "Staff": "Персонал",
          "title": "Заголовок",
          "Search Here": "Искать здесь",
          "Search": "Поиск",
          "Search Results": "Результаты поиска",
          "No results found.": "Результатов не найдено.",
          "TEXT_1": "лорем",
          "TEXT_2": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis",
          "TEXT_3": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur reprehenderit voluptatem debitis. Voluptates et libero reiciendis labore doloremque! Molestias neque quae quos dignissimos eum rem distinctio cumque repellat fugiat nobis.",
          "TEXT_4": "скажем о",
          "TEXT_5": "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.",
          "TEXT_6": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat minus nisi rem incidunt illo repellendus dignissimos, totam ex sit enim, officia ipsum, sequi voluptates quasi?",
          "TEXT_7": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga maiores iure consequatur aliquam voluptate vero quae explicabo enim accusantium ad, voluptatibus, ipsum possimus natus excepturi?",
          "Loading...": "Загрузка...",
          "aboutUs": "О нас",
          "somethingAboutYourDomain": "Что-то о вашем домене",
          "yearsExperienceInOurCompany": "Годы опыта в нашей компании",
          "projectCompleteLast5Years": "Проект завершен за последние 5 лет",
          "clientsHappyWithUs": "Клиенты довольны нами",
          "projectCompleteLast2Years": "Проект завершен за последние 2 года",
          "clientsActiveWithUs": "Клиенты активны с нами",
          "aboutTextDescription": " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          "contactUs": "Связаться с нами",
          "needHelp": "Нужна помощь?",
          "phone": "Телефон",
          "email": "Электронная почта",
          "Your Name": "Ваше имя",
          "Your Surname": "Ваша фамилия",
          "Email Address": "Адрес электронной почты",
          "Subject": "Тема",
          "Message": "Сообщение",
          "Submit Now": "Отправить сейчас",
          "Something went wrong": "Что-то пошло не так",
          "Sent with success": "Успешно отправлено",
          "Error": "Ошибка",
          "PageNotFound": "Страница, которую вы ищете, не существует!",
          "GoBackHome": "Вернуться на главную страницу",
          "Home": "Главная",
          "Portfolio": "Портфолио",
          "Contact": "Контакт",
          "TermsOfUse": "Условия использования",
          "PrivacyPolicy": "Политика конфиденциальности",
          "AgreementConfirmation": "Подтверждение соглашения",
          "ReadTermsAndConditions": "Прочитать условия и положения",
          "ConfirmTerms": "Подтвердить условия",
          "Logout": "Выйти",
          "Log in": "Войти",
          "About": "О нас",
          "3D model": "3D модель",
          "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit",
          "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien egetas tincidunt hendrerit": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien egetas tincidunt hendrerit",
          "Pellentesque in dolor vel malesuada. Donec": "Pellentesque in dolor vel malesuada. Donec",
          "pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.": "pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.",
          "How IT Work": "Как это работает",
          "Simple Work Process": "Простой процесс работы",
          "Concept Creation": "Создание концепции",
          "Sketch Drawing": "Рисование эскизов",
          "3d Print": "3D печать",
          "pellentesque in dolor vel malesuada. Donec finibus libero odio": "Pellentesque in dolor vel malesuada. Donec finibus libero odio",
          "Logged with success": "Успешный вход",
          "Login": "Вход",
          "Login with email and password": "Войти с помощью электронной почты и пароля",
          "Email": "Электронная почта",
          "Password": "Пароль",
          "Sign in": "Войти",
          "OR": "ИЛИ",
          "By signing in with Google, you agree to our terms and conditions.": "Входя через Google, вы соглашаетесь с нашими условиями использования.",
          "Don't have an account?": "Нет учетной записи?",
          "Register": "Регистрация",
          "Login with Google": "Войти через Google",
          "Make an order": "Сделать заказ",
          "Please fill in the following fields": "Пожалуйста, заполните следующие поля",
          "Order submitted successfully!": "Заказ успешно отправлен!",
          "An error occurred while submitting the order.": "Произошла ошибка при отправке заказа.",
          "Material": "Материал",
          "No material selected": "Материал не выбран",
          "Color": "Цвет",
          "No color selected": "Цвет не выбран",
          "Quantity": "Количество",
          "No quantity selected": "Количество не выбрано",
          "Model Details": "Детали модели",
          "Volume": "Объем",
          "Full Name": "Полное имя",
          "Surname": "Фамилия",
          "Phone number": "Номер телефона",
          "City": "Город",
          "Street": "Улица",
          "Zip Code": "Почтовый индекс",
          "Company name (optional)": "Название компании (необязательно)",
          "Type your comments....": "Введите ваши комментарии....",
          "Privacy policy": "Политика конфиденциальности",
          "Welcome to Our Policies": "Добро пожаловать в наши политики",
          "+999 policies and terms": "+999 политик и условий",
          "Maecenas tincidunt hendrerit odio sed consectetur. Duis porta purus sapien, eget pretium augue consectetur ut. Nunc nibh augue, pretium quis imperdiet pellentesque, molestie eget nisi. Sed rutrum sit amet eros ac egestas. Maecenas tincidunt dolor in massa iaculis, vitae dignissim sem finibus. Pellentesque elementum vel arcu sit amet rhoncus. Nulla at eleifend lorem. Praesent et ex sed metus egestas feugiat. Donec velit libero, feugiat ac dictum vel, dignissim id ante. Praesent hendrerit posuere condimentum.": "Маэценас тинцидинт хендрерит одио сед консектетур. Дуис порта пурус сапиен, егет pretium augue консектетур ут. Нунк нибх augue, pretium quis imperdiet pellentesque, molestie eget nisi. Сед рутрум сит амет эрос ац egestas. Маэценас тинцидинт долор ин масса иацулис, витае дигниссим сем финибус. Пеллентеск элементум вел арку сит амет рончус. Нулла ат элефенд лорем. Прасент эт экс сед метус egestas feugiat. Донец велит либеро, feugiat ац диктум вел, дигниссим ид анте. Прасент хендрерит посуере кондиментум.",
          "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.": "Вестибулум егет теллус ронкус, диктум масса а, маттис масса. Крас ин лео семпер, ультрицис лигула нек, орнаре теллус. Суспенди ссе квам рисус, семпер эт ультрицис а, коммодо эу tortor. Фаселлус элементум тинцидинт вариус. Нам фацилисис, анте егет гравида вестибулум, анте ниси feugiat нулла, ин дапибус неке turpis et dolor. Вестибулум ин урна урна.",
          "Terms of use": "Условия использования",
          "Welcome to Our Terms of use page": "Добро пожаловать на страницу наших условий использования",
          "3d model": "3D модель",
          "Choose File or drag on": "Выберите файл или перетащите",
          "Create Portfolio": "Создать портфолио",
          "Photography": "Фотография",
          "Review": "Отзыв",
          "Submit": "Отправить",
          "Client Reviews": "Отзывы клиентов",
          "Leave review": "Оставить отзыв",
          "Name": "Имя",
          "Confirm password": "Подтвердите пароль",
          "I agree with terms and condition": "Я согласен с условиями",
          "I agree with": "Я согласен с",
          "terms and condition": "условиями",
          "Have an account?": "Есть аккаунт?",
          "login": "войти",
          "Enter your first name": "Введите ваше имя",
          "Enter your last name": "Введите вашу фамилию",
          "Password should be at least 8 characters long, with uppercase letters, numbers, and symbols": "Пароль должен содержать не менее 8 символов, с заглавными буквами, цифрами и символами",
          "Re-enter your password": "Введите пароль еще раз",
          "Enter your email address": "Введите ваш адрес электронной почты",
          "Please fill in the following fields:": "Пожалуйста, заполните следующие поля:",
          "Passwords do not match": "Пароли не совпадают",
          "You must agree to the terms and conditions to register.": "Вы должны согласиться с условиями, чтобы зарегистрироваться.",
          "Please select both a material and a color": "Please select both a material and a color",
          "Please select a material": "Please select a material",
          "Please select a color": "Please select a color"
        }
      },
      de: {
        translation: {
          "Welcome": "Willkommen",
          "BEST_3D_VISUALIZER": "Bester 3D-Visualisierer",
          "Check this out": "Check this outDe",
          "Go to 3d visualizier": "Zum 3d-Visualisierer",
          "Show All": "Alles anzeigen",
          "Some useful posts": "Einige nützliche Beiträge",
          "Useful": "Nützlich",
          "Machine": "Maschine",
          "PC": "PC",
          "Staff": "Mitarbeiter",
          "title": "Titel",
          "Search Here": "Hier suchen",
          "Search": "Suche",
          "Search Results": "Suchergebnisse",
          "No results found.": "Keine Ergebnisse gefunden.",
          "TEXT_1": "De lorem",
          "TEXT_2": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis",
          "TEXT_3": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur reprehenderit voluptatem debitis. Voluptates et libero reiciendis labore doloremque! Molestias neque quae quos dignissimos eum rem distinctio cumque repellat fugiat nobis.",
          "TEXT_4": "sagen über",
          "TEXT_5": "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.",
          "TEXT_6": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat minus nisi rem incidunt illo repellendus dignissimos, totam ex sit enim, officia ipsum, sequi voluptates quasi?",
          "TEXT_7": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga maiores iure consequatur aliquam voluptate vero quae explicabo enim accusantium ad, voluptatibus, ipsum possimus natus excepturi?",
          "Loading...": "Wird geladen...",
          "aboutUs": "Über uns",
          "somethingAboutYourDomain": "Etwas über Ihre Domain",
          "yearsExperienceInOurCompany": "Jahre Erfahrung in unserem Unternehmen",
          "projectCompleteLast5Years": "Projekt abgeschlossen in den letzten 5 Jahren",
          "clientsHappyWithUs": "Kunden zufrieden mit uns",
          "projectCompleteLast2Years": "Projekt abgeschlossen in den letzten 2 Jahren",
          "clientsActiveWithUs": "Kunden aktiv mit uns",
          "aboutTextDescription": " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
          "contactUs": "Kontaktieren Sie uns",
          "needHelp": "Brauchen Sie Hilfe?",
          "phone": "Telefon",
          "email": "E-Mail",
          "Your Name": "Ihr Name",
          "Your Surname": "Ihr Nachname",
          "Email Address": "E-Mail Adresse",
          "Subject": "Betreff",
          "Message": "Nachricht",
          "Submit Now": "Jetzt absenden",
          "Something went wrong": "Etwas ist schief gelaufen",
          "Sent with success": "Erfolgreich gesendet",
          "Error": "Fehler",
          "PageNotFound": "Die gesuchte Seite existiert nicht!",
          "GoBackHome": "Zurück zur Startseite",
          "Home": "Startseite",
          "Portfolio": "Portfolio",
          "Contact": "Kontakt",
          "TermsOfUse": "Nutzungsbedingungen",
          "PrivacyPolicy": "Datenschutzrichtlinie",
          "PoweredBy": "Bereitgestellt von",
          "AgreementConfirmation": "Bestätigungsvereinbarung",
          "ReadTermsAndConditions": "Lesen Sie die Allgemeinen Geschäftsbedingungen",
          "ConfirmTerms": "Bedingungen bestätigen",
          "Logout": "Abmelden",
          "Log in": "Einloggen",
          "About": "Über",
          "3D model": "3D Modell",
          "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit",
          "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien egetas tincidunt hendrerit": "Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien egetas tincidunt hendrerit",
          "Pellentesque in dolor vel malesuada. Donec": "Pellentesque in dolor vel malesuada. Donec",
          "pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.": "pellentesque in dolor vel malesuada. Donec finibus libero odio, ac iaculis metus mattis quis. Curabitur a tincidunt risus, non gravida ipsum. Sed at ligula porta, dictum magna id, molestie mi. Mauris et ligula dui. Aliquam erat volutpat. Nam pharetra, risus vitae bibendum pellentesque, dui metus convallis felis, non semper leo leo nec augue. Maecenas vestibulum ornare iaculis. Integer elit arcu, lobortis id felis eu, scelerisque maximus libero.",
          "How IT Work": "Wie es funktioniert",
          "Simple Work Process": "Einfacher Arbeitsprozess",
          "Concept Creation": "Konzeption",
          "Sketch Drawing": "Skizzieren",
          "3d Print": "3D-Druck",
          "pellentesque in dolor vel malesuada. Donec finibus libero odio": "Pellentesque in dolor vel malesuada. Donec finibus libero odio",
          "Logged with success": "Erfolgreich eingeloggt",
          "Login": "Anmeldung",
          "Login with email and password": "Mit E-Mail und Passwort anmelden",
          "Email": "E-Mail",
          "Password": "Passwort",
          "Sign in": "Anmelden",
          "OR": "ODER",
          "By signing in with Google, you agree to our terms and conditions.": "Mit der Anmeldung über Google stimmen Sie unseren Nutzungsbedingungen zu.",
          "Don't have an account?": "Haben Sie kein Konto?",
          "Register": "Registrieren",
          "Login with Google": "Mit Google anmelden",
          "Make an order": "Bestellung aufgeben",
          "Please fill in the following fields": "Bitte füllen Sie die folgenden Felder aus",
          "Order submitted successfully!": "Bestellung erfolgreich eingereicht!",
          "An error occurred while submitting the order.": "Beim Einreichen der Bestellung ist ein Fehler aufgetreten.",
          "Material": "Material",
          "No material selected": "Kein Material ausgewählt",
          "Color": "Farbe",
          "No color selected": "Keine Farbe ausgewählt",
          "Quantity": "Menge",
          "No quantity selected": "Keine Menge ausgewählt",
          "Model Details": "Modelldetails",
          "Volume": "Volumen",
          "Full Name": "Vollständiger Name",
          "Surname": "Nachname",
          "Phone number": "Telefonnummer",
          "City": "Stadt",
          "Street": "Straße",
          "Zip Code": "Postleitzahl",
          "Company name (optional)": "Firmenname (optional)",
          "Type your comments....": "Geben Sie Ihre Kommentare ein....",
          "Privacy policy": "Datenschutzerklärung",
          "Welcome to Our Policies": "Willkommen zu unseren Richtlinien",
          "+999 policies and terms": "+999 Richtlinien und Bedingungen",
          "Maecenas tincidunt hendrerit odio sed consectetur. Duis porta purus sapien, eget pretium augue consectetur ut. Nunc nibh augue, pretium quis imperdiet pellentesque, molestie eget nisi. Sed rutrum sit amet eros ac egestas. Maecenas tincidunt dolor in massa iaculis, vitae dignissim sem finibus. Pellentesque elementum vel arcu sit amet rhoncus. Nulla at eleifend lorem. Praesent et ex sed metus egestas feugiat. Donec velit libero, feugiat ac dictum vel, dignissim id ante. Praesent hendrerit posuere condimentum.": "Maecenas tincidunt hendrerit odio sed consectetur. Duis porta purus sapien, eget pretium augue consectetur ut. Nunc nibh augue, pretium quis imperdiet pellentesque, molestie eget nisi. Sed rutrum sit amet eros ac egestas. Maecenas tincidunt dolor in massa iaculis, vitae dignissim sem finibus. Pellentesque elementum vel arcu sit amet rhoncus. Nulla at eleifend lorem. Praesent et ex sed metus egestas feugiat. Donec velit libero, feugiat ac dictum vel, dignissim id ante. Praesent hendrerit posuere condimentum.",
          "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.": "Vestibulum eget tellus rhoncus, dictum massa a, mattis massa. Cras in leo semper, ultricies ligula nec, ornare tellus. Suspendisse quam risus, semper et ultricies a, commodo eu tortor. Phasellus elementum tincidunt varius. Nam facilisis, ante eget gravida vestibulum, ante nisi feugiat nulla, in dapibus neque turpis et dolor. Vestibulum in urna urna.",
          "Terms of use": "Nutzungsbedingungen",
          "Welcome to Our Terms of use page": "Willkommen auf unserer Seite mit den Nutzungsbedingungen",
          "3d model": "3d-Modell",
          "Choose File or drag on": "Datei auswählen oder ziehen",
          "Create Portfolio": "Portfolio erstellen",
          "Photography": "Fotografie",
          "Review": "Bewertung",
          "Submit": "Einreichen",
          "Name": "Name",
          "Confirm password": "Passwort bestätigen",
          "I agree with terms and condition": "Ich stimme den Bedingungen und Konditionen zu",
          "I agree with": "Ich stimme mit",
          "terms and condition": "Bedingungen und Konditionen",
          "Have an account?": "Haben Sie ein Konto?",
          "login": "Einloggen",
          "Enter your first name": "Geben Sie Ihren Vornamen ein",
          "Enter your last name": "Geben Sie Ihren Nachnamen ein",
          "Password should be at least 8 characters long, with uppercase letters, numbers, and symbols": "Das Passwort sollte mindestens 8 Zeichen lang sein, mit Großbuchstaben, Zahlen und Symbolen",
          "Re-enter your password": "Geben Sie Ihr Passwort erneut ein",
          "Enter your email address": "Geben Sie Ihre E-Mail-Adresse ein",
          "Please fill in the following fields:": "Bitte füllen Sie die folgenden Felder aus:",
          "Passwords do not match": "Passwörter stimmen nicht überein",
          "You must agree to the terms and conditions to register.": "Sie müssen den Bedingungen und Konditionen zustimmen, um sich zu registrieren.",
          "Please select both a material and a color": "Please select both a material and a color",
          "Please select a material": "Please select a material",
          "Please select a color": "Please select a color"
        }
      }
    },
    fallbackLng: "de",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;