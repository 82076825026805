import React from 'react';
import { useSelector } from "react-redux";
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { pageTitle } from '../PageTitle';
import Form from './Form';
import LoggedForm from './LoggedForm'
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    
    pageTitle('Contact Us');
    const { t } = useTranslation();
    return (
        <>
        <Header></Header>
        <div className="contact__area section-padding">
            <div className="container">
            <div className='pt-50'></div>
                <div className="row mb-60">
                    <div className="col-xl-5 col-lg-6">
                        <div className="contact__area-title">
                            <span className="subtitle">{t('contactUs')}</span>
                            <h2>{t('needHelp')}<br/> {t('contactUs')}</h2> 
                        </div>
                        <div className="contact__area-info">
                            <div className="contact__area-info-item">
                                <div className="contact__area-info-item-icon">
                                    <i className="far fa-phone-alt"></i>
                                    <span>{t('phone')} :</span>
                                </div>
                                <div className="contact__area-info-item-content">
                                    <a href="tel:+123(458)896895">+123 ( 458 ) 896 895</a>
                                </div>
                            </div>
                            <div className="contact__area-info-item">
                                <div className="contact__area-info-item-icon">
                                    <i className="fal fa-envelope"></i>
                                    <span>{t('email')} :</span>
                                </div>
                                <div className="contact__area-info-item-content">
                                    <a href="mailto:support@gmail.com">support@gmail.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <div className="contact__area-bottom">
                            <div className="contact__area-bottom-form page ">
                                {!isAuthenticated ? <Form /> : <LoggedForm />}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='pt-100'></div>
            </div>
        </div>
        
        <Footer></Footer>
        </>
    );
};

export default Contact;
