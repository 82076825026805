import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Pagination } from "swiper";
import { pageTitle } from "../PageTitle";
import Form from "./Form";
import LoggedForm from "./LoggedForm";
import { fetchReviews } from "../../api/review";
import { useTranslation } from 'react-i18next';

const Review = () => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [reviews, setReviews] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const loadReviews = async () => {
      const fetchedReviews = await fetchReviews(token);
      if (fetchedReviews) {
        setReviews(fetchedReviews);
      }
    };
    loadReviews();
  }, [token]);

  pageTitle(t("Leave review"));
  return (
    <>
      <Header></Header>
      <div className="contact__area section-padding">
        <div className="container">
          <div className="row mt-3">
            <div className="col-12 col-lg-6">
              <div className="reviews__area-left">
                <div className="reviews__area-left-title">
                  <h2>{t("Client Reviews")}</h2>
                </div>
                <Swiper
                  slidesPerView={1}
                  loop={true}
                  spaceBetween={30}
                  pagination={{ clickable: true }}
                  modules={[Pagination]}
                >
                  {reviews.map((review, index) => (
                    <SwiperSlide key={index}>
                      <div className="reviews__area-left-item">
                        <p>{review.review}</p>
                        <div className="reviews__area-left-item-client">
                          <div className="reviews__area-left-item-client-content">
                            <h5>
                              {review.name} {review.surname}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="reviews__area-left-quote">
                  <img src="assets/img/icon/quote.png" alt="" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="contact__area-bottom">
                <div className="contact__area-bottom-form top-o">
                  {!isAuthenticated ? <Form /> : <LoggedForm />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Review;
