import React from 'react';
import YouTube from 'react-youtube';

const Video = ({ videoId }) => {
    return (
        <div>
            <div className="container p-0 mt-3 mb-3">
                <div className="row">
                    <div className="col-xl-12">
                        <div>
                            <YouTube videoId={videoId} opts={{ width: '100%', height: '500px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video;