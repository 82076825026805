import React from 'react';

const PolicyBanner = ({ text }) => {
  return (
    <div className="banner">
        
      <h1>{text}</h1>

      <div className="square-one"></div>
      <div className="square-two"></div>
    </div>
  );
};

export default PolicyBanner;