import React, { useState } from "react";
import { submitGuestReview } from "../../api/review";
import { toast } from 'react-toastify';

const Form = () => {
  const [reviewData, setReviewData] = useState({});
  const errorMSG = () => toast.error('Something went wrong', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });
const succesMSG = () => toast.success('Sent with succes', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });
  const handleInputChange = (event) => {
    setReviewData({
      ...reviewData,
      [event.target.name]: event.target.value,
    });
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        const response = await submitGuestReview(reviewData);

        if (response.status === 400) {
            errorMSG();
        } else {
            succesMSG(); // Function call on success
        }
    } catch (error) {
        errorMSG(); // Function call in case of an error during the API call
    }
};


  return (
    <>
      <form className="review" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12 mb-25">
            <div className="contact__area-bottom-form-item ">
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                required
                className="noChanging"
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12 mb-25">
            <div className="contact__area-bottom-form-item">
              <input
                type="text"
                name="surname"
                placeholder="Your Surname"
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12 mb-25">
            <div className="contact__area-bottom-form-item">
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                required
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-sm-12 mb-30">
            <div className="contact__area-bottom-form-item">
              <textarea
                type="text"
                name="review"
                placeholder="Review"
                rows={4}
                required
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="contact__area-bottom-form-item">
              <button className="theme-btn-1" type="submit">
                Submit<i className="far fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
