import React, { useState } from 'react';
import Footer from './../Footer/Footer';
import Header from './../Header/Header';
import { pageTitle } from './../PageTitle';
import Graph from './tabs/Graph'
import Chat from './tabs/Chat'
import Questions from './tabs/Questions'
import AddOptions from './tabs/AddOptions'
import Orders from './tabs/Orders'
import Reviews from './tabs/Reviews'

const AdminDashboard = () => {
    pageTitle('Admin page');
    const [activeTab, setActiveTab] = useState('Graph');

    return (
        <>
            <Header/>

            
                <div className="info__details section-padding">
                    <div className="admin">
                        <div className="row">
                            <div className="col-2 section-tab">
                                <div className={`tabSelector ${activeTab === 'Graph' ? 'active' : ''}`} onClick={() => setActiveTab('Graph')}>Graph</div>
                                <div className={`tabSelector ${activeTab === 'Questions' ? 'active' : ''}`} onClick={() => setActiveTab('Questions')}>Questions</div>
                                <div className={`tabSelector ${activeTab === 'Chat' ? 'active' : ''}`} onClick={() => setActiveTab('Chat')}>Chat</div>
                                <div className={`tabSelector ${activeTab === 'AddOptions' ? 'active' : ''}`} onClick={() => setActiveTab('AddOptions')}>AddOptions</div>
                                <div className={`tabSelector ${activeTab === 'Orders' ? 'active' : ''}`} onClick={() => setActiveTab('Orders')}>Orders</div>
                                <div className={`tabSelector ${activeTab === 'Reviews' ? 'active' : ''}`} onClick={() => setActiveTab('Reviews')}>Reviews</div>

                                
                            </div>
                            <div className="col-10">
                                {activeTab === 'Graph' && <Graph />}
                                {activeTab === 'Questions' && <Questions />}
                                {activeTab === 'Chat' && <Chat />}
                                {activeTab === 'AddOptions' && <AddOptions />}
                                {activeTab === 'Orders' && <Orders />}
                                {activeTab === 'Reviews' && <Reviews />}
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </>
    );
};

export default AdminDashboard;