import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const { t } = useTranslation();
    return (
        <li>
            <Link to="/">{t('Home')}</Link>
        </li>
    );
};

export const Page = () => {
    const { t } = useTranslation();
    return (
        <li>
            <Link to="/about">{t('About')}</Link>
        </li>
    );
};

export const Portfolio = () => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <li>
                <Link to="/portfolio">{t('Portfolio')}</Link>
            </li>
            <li>
                <Link to="/model">{t('3D model')}</Link>
            </li>
        </Fragment>
    );
};

export const User = () => (
    <Fragment></Fragment>
);

export const CurrentLanguage = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    return (
        <Link>{currentLanguage.toUpperCase()}</Link>
    );
};

export const OtherLanguages = () => {
    const { i18n } = useTranslation();
    const availableLanguages = ['en', 'ru', 'de'];
    const currentLanguage = i18n.language;
    const otherLanguages = availableLanguages.filter(lang => lang !== currentLanguage);

    return (
        <Fragment>
            {otherLanguages.map(lang => (
                <li key={lang} onClick={() => i18n.changeLanguage(lang)}>
                    <Link>{lang.toUpperCase()}</Link>
                </li>
            ))}
        </Fragment>
    );
};
