import React, { useState } from "react";
import { submitGuestMessage } from "../../api/messages";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const Form = () => {
  const { t } = useTranslation();
  const [messageData, setMessageData] = useState({});

  const initialFormState = {
    name: '',
    surname: '',
    email: '',
    subject: '',
    message: '',
  };

  const errorMSG = () => toast.error(t('Something went wrong'), {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const succesMSG = () => toast.success(t('Sent with success'), {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const handleInputChange = (event) => {
    setMessageData({
      ...messageData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await submitGuestMessage(messageData);

      if (response.status === 400) {
        errorMSG();
      } else {
        succesMSG(); // Function call on success
        setMessageData(initialFormState); // Clear the form
      }
    } catch (error) {
      errorMSG(); // Function call in case of an error during the API call
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12 mb-25">
            <div className="contact__area-bottom-form-item">
              <input
                type="text"
                name="name"
                placeholder={t("Your Name")}
                required
                onChange={handleInputChange}
                value={messageData.name} // Bind the value to messageData state
              />
            </div>
          </div>
          <div className="col-sm-12 mb-25">
            <div className="contact__area-bottom-form-item">
              <input
                type="text"
                name="surname"
                placeholder={t("Your Surname")}
                required
                onChange={handleInputChange}
                value={messageData.surname} // Bind the value to messageData state
              />
            </div>
          </div>
          <div className="col-sm-12 mb-25">
            <div className="contact__area-bottom-form-item">
              <input
                type="email"
                name="email"
                placeholder={t("Email Address")}
                required
                onChange={handleInputChange}
                value={messageData.email} // Bind the value to messageData state
              />
            </div>
          </div>
          <div className="col-sm-12 mb-25">
            <div className="contact__area-bottom-form-item">
              <input
                type="text"
                name="subject"
                placeholder={t("Subject")}
                required
                onChange={handleInputChange}
                value={messageData.subject} // Bind the value to messageData state
              />
            </div>
          </div>
          <div className="col-sm-12 mb-30">
            <div className="contact__area-bottom-form-item">
              <textarea
                type="text"
                name="message"
                placeholder={t("Message")}
                required
                onChange={handleInputChange}
                value={messageData.message} // Bind the value to messageData state
              ></textarea>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="contact__area-bottom-form-item">
              <button className="theme-btn-1" type="submit">
                {t("Submit Now")}<i className="far fa-long-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Form;
