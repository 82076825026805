import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SVHOST + '/api',
});

export const fetchReviews = async (token) => {
    try {
      const response = await api.get('/review/all', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('something went wrong:', error);
      return null;
    }
  };
  
  export const deleteReview = async (reviewId, token) => {
    try {
      const response = await api.delete(`/review/${reviewId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.status === 200 || response.status === 204) {
        return true;
      }
    } catch (error) {
      console.error('Something went wrong:', error);
      throw error;
    }
  }
  
  export const submitUserReview = async (reviewData, token) => {
    try {
      const response = await api.post('/review/submit-user', reviewData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };
  
  export const submitGuestReview = async (reviewData) => {
    try {
      const response = await api.post('/review/submit-guest', reviewData);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };