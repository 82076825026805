import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Carousel = () => {
    const { t } = useTranslation();

    return (
        <>
        <div className="reviews__area">
            <div className="container">
                <div className="row">
                    <div className="offset-xl-4 offset-lg-2 col-xl-8 col-lg-10">
                        <div className="reviews__area-left useful">
                            <div className="reviews__area-left-title">
                                <h2>{t('Useful')}</h2> 
                            </div>                            
                            <Swiper
                                slidesPerView={1}
                                loop={true}
                                spaceBetween={30}
                                pagination={{ clickable: true }}
                                modules={[Pagination]}
                            >
                                <SwiperSlide>
                                    <div className="reviews__area-left-item">
                                        <div className="reviews__area-left-item-client">
                                            <div className="reviews__area-left-item-client-content">
                                                <h5>
                                                    <Link to="info">{t('Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien eget pretium augue consectetur ut Nunc nibh augue pretium quis Maecenas tincidunt hendrerit')}</Link>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="reviews__area-left-item">
                                        <div className="reviews__area-left-item-client">
                                            <div className="reviews__area-left-item-client-content">
                                                <h5>
                                                    <Link to="info">{t('Maecenas tincidunt hendrerit odio sed consectetur Duis porta purus sapien egetas tincidunt hendrerit')}</Link>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Carousel;
