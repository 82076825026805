import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  layerHeight: [
    { name: 'Standart Resolution', height: 0.002 },
    { name: 'High Resolution', height: 0.001 },
  ],
  selectedHeightIndex: 0,
};

const layerHeightSlice = createSlice({
  name: 'height',
  initialState,
  reducers: {
    setSelectedHeightIndex: (state, action) => {
      state.selectedHeightIndex = action.payload;
    },
  },
});

export const { setSelectedHeightIndex} = layerHeightSlice.actions;

export default layerHeightSlice.reducer;
