import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="footer__area pt-60 pb-60">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12">
                            <div className="footer__area-widget t-center">
                                <div className="footer__area-widget-about">
                                    <div className="footer__area-widget-about-social main">
                                        <ul>
                                            <li><a href='https://www.youtube.com/'><i className="fab fa-youtube"></i></a> </li>
                                            <li><a href='https://www.telegram.com/'><i className="fab fa-telegram"></i></a> </li>
                                            <li><a href='https://www.instagram.com/'><i className="fab fa-instagram"></i></a> </li>
                                        </ul>
                                    </div>
                                    <div className="footer__area-widget-about-menu main">
                                        <ul>
                                            <li><Link to='/'>{t('Home')}</Link></li>
                                            <li><Link to='/portfolio'>{t('Portfolio')}</Link></li>
                                            <li><Link to='/contact'>{t('Contact')}</Link></li>
                                            <li><Link to='/terms-of-use'>{t('TermsOfUse')}</Link></li>
                                            <li><Link to='/privacy-policy'>{t('PrivacyPolicy')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright__area">
                <div className="container">
                    <div className="row align-items-center copyright__area-border">
                        <div className="col-xl-12">
                            <div className="copyright__area-left t-center">
                                <p>Powered by<a href="https://github.com/alexandrvolinet"> Nikushimi</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
