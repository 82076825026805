import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SVHOST + '/api',
});

export const fetchMessages = async (token) => {
    try {
      const response = await api.get('/message/all', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      console.error('something went wrong:', error);
      return null;
    }
  };
  
  export const deleteMessage = async (messageId, token) => {
    try {
      const response = await api.delete(`/message/${messageId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (response.status === 200 || response.status === 204) {
        return true;
      }
    } catch (error) {
      console.error('Something went wrong:', error);
      throw error;
    }
  }
  
  export const submitUserMessage = async (messageData, token) => {
    try {
      const response = await api.post('/message/submit-user', messageData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };
  
  export const submitGuestMessage = async (messageData) => {
    try {
      const response = await api.post('/message/submit-guest', messageData);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };