import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchMessages, deleteMessage } from '../../../api/messages';

function Questions() {
  const [messages, setMessages] = useState([]);
  const [expandedMessageId, setExpandedMessageId] = useState(null);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    async function loadMessages() {
      const data = await fetchMessages(token);
      if (data) {
        setMessages(data.reverse().map(message => ({ ...message, wasExpanded: false })));
      }
    }
    loadMessages();
  }, [token]);

  const toggleMessage = (id) => {
    setMessages(messages.map(message => 
      message._id === id ? { ...message, isExpanded: !message.isExpanded, wasExpanded: true } : { ...message, isExpanded: false }
    ));
    setExpandedMessageId(expandedMessageId === id ? null : id);
  };

  const renderMessageText = (message) => {
    const maxCharacters = 100;
    return message.isExpanded ? message.message : `${message.message.substring(0, maxCharacters)}...`;
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessage(messageId, token);
      setMessages(messages.filter(message => message._id !== messageId));
    } catch (error) {
      console.error('error', error);
    }
  };

  return (
    <div className="messages-container">
      {messages.map(message => (
        <div
          key={message._id}
          className={`message-item ${message.wasExpanded ? 'was-expanded' : ''} ${message.isExpanded ? 'expanded' : ''}`}
          onClick={() => toggleMessage(message._id)}
        >
          <h2>{message.subject}</h2>
          <p>{renderMessageText(message)}</p>
          <button
            className="delete-button"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteMessage(message._id);
            }}
          >
            &#10005;
          </button>
          {message.isExpanded && (
            <div className="message-details">
              <p>Name: {message.name}</p>
              <p>Surname: {message.surname}</p>
              <p>Email: {message.email}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Questions;